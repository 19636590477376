import axios from 'axios';
import {
  UPDATE_EMPLOYEE_TRAINING,
  ERROR_UPDATE_EMPLOYEE_TRAINING,
  REMOVE_EMPLOYEE_TRAINING,
  ERROR_REMOVE_EMPLOYEE_TRAINING,
} from '../types';

export const updateEmployeeTraining = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.put(apiUrl);
    dispatch({
      type: UPDATE_EMPLOYEE_TRAINING,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_UPDATE_EMPLOYEE_TRAINING,
      payload: error,
    });
  }
};
export const removeEmployeeTraining = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.put(apiUrl);
    dispatch({
      type: REMOVE_EMPLOYEE_TRAINING,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_REMOVE_EMPLOYEE_TRAINING,
      payload: error,
    });
  }
};
