import {
  ANNOUNCEMENT,
  ANNOUNCEMENT_ERROR,
  ANNOUNCEMENT_LIST,
  ANNOUNCEMENT_LIST_ERROR,
  DELETE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT_ERROR,
  RESET,
} from '../types';

const initialState = {
  announcementStatus: '',
  announcementLoading: true,
  announcementError: '',
  announcementList: [],
  announcementListError: '',
  deleteAnnouncement: '',
  deleteAnnouncmentError: '',
  deleteLoading: true,
};

/* eslint-disable func-names */
export default function (state = initialState, action) {
  /* eslint-enable */
  switch (action.type) {
    case ANNOUNCEMENT:
      return {
        ...state,
        announcementStatus: action.payload,
        announcementLoading: false,
      };
    case ANNOUNCEMENT_ERROR:
      return {
        ...state,
        announcementLoading: false,
        announcementError: action.payload.message,
      };
    case ANNOUNCEMENT_LIST:
      return {
        ...state,
        announcementLoading: false,
        announcementList: action.payload,
      };
    case ANNOUNCEMENT_LIST_ERROR:
      return {
        ...state,
        announcementLoading: false,
        announcementListError: action.payload.message,
      };
    case DELETE_ANNOUNCEMENT:
      return {
        ...state,
        deleteLoading: false,
        deleteAnnouncement: action.payload,
      };
    case DELETE_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        deleteLoading: false,
        deleteAnnouncmentError: action.payload,
      };
    case RESET:
      return {
        ...state,
        announcementStatus: '',
        announcementLoading: true,
        announcementError: '',
        announcementListError: '',
        deleteAnnouncement: '',
        deleteAnnouncmentError: '',
        deleteLoading: true,
      };
    default: return state;
  }
}
