import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateEmployeeTraining, removeEmployeeTraining } from '../Store/actions/employeeAction';
import ApiDropDown from '../Components/Common/ApiDropdown';
import ApiTypeAhead from '../Components/Common/ApiTypeAhead';
import Loader from '../Components/Loader';
import { Context } from '../Context/MenuContext';
import PageHeader from '../Components/Common/PageHeader';
import Alert from '../Components/Common/Alert';

const EmployeeTraining = () => {
  const dispatch = useDispatch();
  const [outputMessage, setOutputMessage] = useState('');
  const [showResult, setShowResult] = useState(false);

  const employeeList = useSelector((state) => state.employeeList);
  const {
    userAccess,
  } = employeeList;

  const employeeTrainingResult = useSelector((state) => state.employeeTraining);
  const {
    updateEmployeeTrainingResult,
    updateEmployeeTrainingResultError,
    removeEmployeeTrainingResult,
    removeEmployeeTrainingResultError,
  } = employeeTrainingResult;

  const [trainingLevel, setTrainingLevel] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [loader, setLoader] = useState(null);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [, , , setSelectedMenu, , setSelectedSubMenu] = useContext(Context);

  const [hasAccess, setHasAccess] = useState(false);
  useEffect(() => {
    setSelectedSubMenu('employeeTraining');
    setSelectedMenu('userManagement');
  }, []);
  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    // this field updates twice, and we want to grab the one that contains employee ID to reference in RDS
    if (name === 'employeeName' && /\d/.test(value)) {
      setEmployeeId(value);
    }
    if (name === 'trainingLevel') {
      setTrainingLevel(value);
    }
  };

  useEffect(() => {
    if (userAccess?.length > 0
      && userAccess.filter((i) => i.pageCode === 'employeeTraining' && i.writeAccess)?.length > 0) {
      setHasAccess(true);
    }
  }, []);

  function updateTraining() {
    if (employeeId !== '' && trainingLevel !== '') {
      setLoader(true);
      const apiUrl = `/api/employee/OverrideTraining/${employeeId}/${trainingLevel}`;
      dispatch(updateEmployeeTraining(apiUrl));
      setOutputMessage('Successfully updated');
      setShowResult(true);
    }
  }

  function removeTraining() {
    if (employeeId !== '' && trainingLevel !== '') {
      setLoader(true);
      const apiUrl = `/api/employee/RemoveTraining/${employeeId}/${trainingLevel}`;
      dispatch(removeEmployeeTraining(apiUrl));
      setOutputMessage('Successfully removed');
      setShowResult(true);
    }
  }
  useEffect(() => {
    if (updateEmployeeTrainingResultError !== '') {
      setErrorMessage(updateEmployeeTrainingResultError);
      setShowError(true);
    }
  }, [updateEmployeeTrainingResultError]);
  useEffect(() => {
    if (removeEmployeeTrainingResultError !== '') {
      setErrorMessage(removeEmployeeTrainingResultError);
      setShowError(true);
    }
  }, [removeEmployeeTrainingResultError]);
  useEffect(() => {
    if (updateEmployeeTrainingResult || removeEmployeeTrainingResult || showError) {
      setLoader(false);
      setTrainingLevel(null);
      setEmployeeId(null);
    }
  }, [updateEmployeeTrainingResult, removeEmployeeTrainingResult], [showError]);

  const onChange = () => {
    setShowResult(false);
  };

  return (
    <div className="wrapper">
      <div className="main">
        <PageHeader pageTitle="Employee Training" />
        <div className="page-content">
          <div className="card shadow">
            <div className="card-body">
              <div className="mb-3">
                <ApiTypeAhead
                  name="employeeName"
                  defaultId={null}
                  displayKey="employeeName"
                  query={null}
                  title="Employee Name"
                  placeholder="Type to search"
                  url="/api/lookup/people"
                  onChange={handleChange}
                  required
                />
              </div>
              <ApiDropDown
                name="trainingLevel"
                value={trainingLevel}
                placeholder="Pick One..."
                title="Training Level"
                url="/api/lookup/TrainingLevels"
                onChange={handleChange}
                required
              />
            </div>
            <div className="card-footer text-end">
              {hasAccess
                ? (
                  <>
                    <button
                      type="button"
                      className={`btn btn-primary ${(employeeId === null || trainingLevel === null) ? 'disabled' : ''}`}
                      data-dismiss="modal"
                      onClick={updateTraining}
                    >
                      Add
                    </button>
                    {'  '}
                    <button
                      type="button"
                      className={`btn btn-danger ${(employeeId === null || trainingLevel === null) ? 'disabled' : ''}`}
                      onClick={removeTraining}
                    >
                      Remove
                    </button>
                  </>
                ) : ''}
            </div>
          </div>
        </div>
        {
    updateEmployeeTrainingResultError === '' && removeEmployeeTrainingResultError === '' ? (

      <>
        {showResult && !loader ? (
          <>
            <div className="modal fade show" style={{ display: 'block' }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="newFeedbackModalLabel">{outputMessage}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { onChange(); setOutputMessage(''); }} />
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-outline-primary" onClick={() => { onChange(); setOutputMessage(''); }}>OK</button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : ''}
      </>
    )
      : (

        showError ? (
          <div className="col-12">
            <Alert
              message={errorMessage}
              class="light-danger"
              onClose={() => {
                setShowError(false); setLoader(false); setShowResult(false); setEmployeeId(''); setTrainingLevel('');
              }}
            />
          </div>
        ) : ''

      )
}
        {showResult && !showError && <div className="modal-backdrop fade show" />}
      </div>
      {loader && !showError ? <Loader /> : ''}

    </div>
  );
};

export default EmployeeTraining;
