import axios from 'axios';
import {
  GET_MODULES, ERROR_GET_MODULES, DELETE_MODULE, ERROR_DELETE_MODULE,
} from '../types';

// eslint-disable-next-line import/prefer-default-export
export const getModules = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_MODULES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_GET_MODULES,
      payload: error,
    });
  }
};
export const deleteModule = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.delete(apiUrl);
    dispatch({
      type: DELETE_MODULE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_DELETE_MODULE,
      payload: error,
    });
  }
};
