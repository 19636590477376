import axios from 'axios';
import {
  ANNOUNCEMENT,
  ANNOUNCEMENT_ERROR,
  ANNOUNCEMENT_LIST,
  ANNOUNCEMENT_LIST_ERROR,
  DELETE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT_ERROR,
} from '../types';

// eslint-disable-next-line import/prefer-default-export
export const saveAndUpdateAnnouncement = (apiUrl, payload) => async (dispatch) => {
  try {
    const res = await axios.post(apiUrl, payload);
    dispatch({
      type: ANNOUNCEMENT,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ANNOUNCEMENT_ERROR,
      payload: error,
    });
  }
};

export const getAnnouncement = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: ANNOUNCEMENT_LIST,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ANNOUNCEMENT_LIST_ERROR,
      payload: error,
    });
  }
};
export const deleteSelectedAnnouncements = (apiUrl, payload) => async (dispatch) => {
  try {
    const res = await axios.delete(apiUrl, payload);
    dispatch({
      type: DELETE_ANNOUNCEMENT,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ANNOUNCEMENT_ERROR,
      payload: error,
    });
  }
};
