import React, { useState } from 'react';
import {
  faBars, faUser, faClock, faBox,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Popup from '../Popup';

const NewFeedbacks = (props) => {
  const { feedbacks, hasAccess } = props;
  const [viewfeedbackButton, setViewfeedbackButton] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [viewfeedback, setViewfeedback] = useState(true);
  const [selectedFeedback, setSelectedFeedback] = useState({});
  const settingDisplay = (props) => {
    setViewfeedback(props);
    setViewfeedbackButton(props);
  };
  const handlePopupChange = () => {
    setShowPopup(false);
  };
  return (
    <>
      <div
        className="tab-pane fade show active"
        id="pills-new"
        role="tabpanel"
        aria-labelledby="pills-new-tab"
      >
        <div className="d-flex mb-3">
          <div className="btn-group ms-auto">
            <div type="button" className={`btn btn-outline-secondary ${viewfeedbackButton ? 'active' : ''}`} onClick={() => settingDisplay(true)}>
              <FontAwesomeIcon icon={faBars} />
            </div>
            <div type="button" className={`btn btn-outline-secondary ${viewfeedbackButton ? '' : 'active'}`} onClick={() => settingDisplay(false)}>
              <FontAwesomeIcon icon={faBox} />
            </div>
          </div>
        </div>
        <div className={`${viewfeedback ? 'list-view' : 'grid-view'}`}>
          {
       feedbacks.map((item) => (
         <div
           className="card feedback-card"
           data-bs-toggle="modal"
           data-bs-target="#newFeedbackModal"
           onClick={() => { setShowPopup(true); setSelectedFeedback(item); }}
           type="button"
         >
           <div className="card-body">
             <p id="description">
               {item.description}
             </p>
             <div className="feedback-info">
               <FontAwesomeIcon icon={faUser} className="me-1" />
               {' '}
               {' '}

               {item.userName ? item.userName : 'Anonymous User'}

             </div>
             <div className="feedback-time">
               <FontAwesomeIcon icon={faClock} className="me-1" />
               {' '}
               {' '}
               { moment(item.createdOn).format('MM/DD/YYYY') }
             </div>
           </div>

         </div>

       ))
        }
        </div>
        {showPopup && <div className="modal-backdrop fade show" />}

        <Popup
          isOpen={showPopup}
          feedback={selectedFeedback}
          onChange={handlePopupChange}
          hasAccess={hasAccess}
          tab="open"
        />

      </div>

    </>
  );
};
export default NewFeedbacks;
