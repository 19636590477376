import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

const Activity = (props) => {
  const {
    title, detail, time, icon,
  } = props;
  return (
    <div className="activity-item">
      <div className="activity-icon">
        {/* <i className="fa-solid fa-file-invoice-dollar" /> */}
        <FontAwesomeIcon icon={icon} />
      </div>
      <h6 className="text-uppercase text-primary">{title}</h6>

      {detail}

      <p className="small text-muted">
        <FontAwesomeIcon icon={faClock} className="me-1" />
        {time}
      </p>
    </div>
  );
};
export default Activity;
