import React from 'react';

const Button = (props) => {
  const {
    className,
    value,
    onClick,
  } = props;
  return (
    <div>
      <button type="button" className={className} onClick={onClick}>{value}</button>
    </div>
  );
};
export default Button;
