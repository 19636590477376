import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

const FeedbackScoreCard = (props) => {
  const {
    title, count, percent, duration, type,
  } = props;
  return (
    <div id="feedback" className="card">
      <div className="card-body">
        <p className="h6 text-muted text-uppercase mb-0">
          {title}
        </p>
        <p className="h1 my-1 my-sm-4">{count}</p>
        { percent === 0 ? ''
          : (
            <p className={`${type === 'success' ? 'text-success' : 'text-danger'} mb-0`}>
              {percent !== 0 ? <FontAwesomeIcon icon={type === 'success' ? faArrowUp : faArrowDown} /> : ''}
              {' '}
              {percent < 0 ? percent * -1 : percent}
              {percent === 0 ? '' : '%'}
            </p>
          )}
        <p className="small text-muted mb-0">
          {duration}
        </p>
      </div>
    </div>
  );
};

export default FeedbackScoreCard;
