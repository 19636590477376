import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Context as MenuContext } from '../Context/MenuContext';
import PageHeader from '../Components/Common/PageHeader';
import ServiceGraph from '../Components/ServiceTrendingTool/ServiceGraph';
import Loader from '../Components/Loader';
import ServiceFilter from '../Components/ServiceTrendingTool/ServiceFilter';
import TrendingServiceGraph from '../Components/ServiceTrendingTool/TrendingServiceGraph';
import Tabs from '../Components/Common/Tabs';
import ForecastAnalytics from '../Components/ServiceTrendingTool/ForecastAnalytics';

const ServiceTrendingTool = () => {
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState('day');
  const [category, setCategory] = useState();
  const [products, setProducts] = useState();

  const [, , , setSelectedMenu] = useContext(MenuContext);
  const [services, setServices] = useState([]);
  const [series, setSeries] = useState([]);
  const [trendingData, setTrendingData] = useState([]);
  const [trendingCategory, setTrendingCategory] = useState([]);
  const [trendingSeries, setTrendingSeries] = useState([]);
  const [trendingCumulativeCategory, setTrendingCumulativeCategory] = useState([]);
  const [trendingCumulativeSeries, setTrendingCumulativeSeries] = useState([]);
  const [forecastingCategory, setForecastingCategory] = useState();
  const [forecasting0verallSeries, setForecastingOverallSeries] = useState([]);
  const [forecastingServiceSeries, setForecastingServiceSeries] = useState([]);
  const [forecastingSeries, setForecastingSeries] = useState();

  const [tab, setTab] = useState('serviceUsage');
  const tabList = [
    { value: 'serviceUsage', label: 'Service Usage' },
    { value: 'serviceTrending', label: 'Trending Service' },
    { value: 'forecastAnalytics', label: 'Forecast Analytics' },
  ];

  const servicesAnalytics = useSelector((state) => state.servicesAnalytics);
  const {
    ServiceAnalyticsData, ServiceAnalyticsDataLoading, TrendingServiceData, TrendingServiceDataLoading, ForecastingServiceData,
    ForecastingServiceDataLoading, TrendingServiceCumulativeData, TrendingServiceCumulativeDataLoading,
  } = servicesAnalytics;

  // Services Analytics Graph data binding
  useEffect(() => {
    if (ServiceAnalyticsData.length > 0) {
      const data = ServiceAnalyticsData.slice(1).map((item) => ({
        name: item[0], data: JSON.parse(item[1]), date: item[2].slice(1, -1).split(',').map((date) => date.trim()),
      }));

      const allDates = data.reduce((dates, entry) => {
        entry.date.forEach((date) => {
          if (!dates.includes(date)) {
            dates.push(date);
          }
        });
        return dates;
      }, []);

      if (range !== 'weekly') {
        allDates.sort();
      }

      // Prepare series data
      const series = data.map((entry) => ({
        name: entry.name,
        data: allDates.map((date) => {
          const index = entry.date.indexOf(date);
          return index !== -1 ? entry.data[index] : null;
        }),
      }));

      // Prepare service for filters
      const service = data.map((entry) => ({
        label: entry.name,
        value: entry.name,
      }));

      setServices(service);
      setProducts(series);
      setSeries(series);
      setCategory(allDates);
      setLoading(true);
    }
  }, [ServiceAnalyticsData]);

  useEffect(() => {
    if (TrendingServiceData.length > 0) {
      const data = TrendingServiceData.slice(1).map((item) => ({
        name: item[0],
        data: JSON.parse(item[1]),
        date: item[2].slice(1, -1).split(',').map((date) => date.trim()),
        difference: JSON.parse(item[4]),
      }));

      const allDates = data.reduce((dates, entry) => {
        entry.date.forEach((date) => {
          if (!dates.includes(date)) {
            dates.push(date);
          }
        });
        return dates;
      }, []);

      // Sort all dates chronologically
      allDates.sort();

      // Prepare series data
      const series = data.map((entry) => ({
        name: entry.name,
        data: allDates.map((date) => {
          const index = entry.date.indexOf(date);
          return index !== -1 ? entry.data[index] : 0;
        }),
        difference: allDates.map((date) => {
          const index = entry.date.indexOf(date);
          return index !== -1 ? entry.difference[index] : 0;
        }),
      }));

      setTrendingSeries(series.slice(0, 5));
      setTrendingData(series);
      setTrendingCategory(allDates);
      setLoading(true);
    }
  }, [TrendingServiceData]);

  useEffect(() => {
    if (TrendingServiceCumulativeData.length > 0) {
      const cumulativeData = TrendingServiceCumulativeData.slice(1).map((item) => ({
        name: 'Services', data: JSON.parse(item[0]), date: item[1].slice(1, -1).split(',').map((date) => date.trim()),
      }));

      const allDates = cumulativeData.reduce((dates, entry) => {
        entry.date.forEach((date) => {
          if (!dates.includes(date)) {
            dates.push(date);
          }
        });
        return dates;
      }, []);
      allDates.sort();

      // Prepare series data
      const CumulativeSeries = cumulativeData.map((entry) => {
        const data = allDates.map((date) => {
          const index = entry.date.indexOf(date);
          return index !== -1 ? entry.data[index] : 0;
        });

        // Calculate differences between consecutive data points
        const difference = data.map((value, index) => {
          if (index === 0) return 0;
          return value - data[index - 1];
        });

        return {
          name: entry.name,
          data,
          difference,
        };
      });

      setTrendingCumulativeSeries(CumulativeSeries);
      setTrendingCumulativeCategory(allDates);
      setLoading(true);
    }
  }, [TrendingServiceCumulativeData]);

  useEffect(() => {
    if (ForecastingServiceData?.actual?.length > 0) {
      const prevMonth = ForecastingServiceData?.actual?.map((item) => item.month) || [];
      const nextMonth = ForecastingServiceData.forecast?.map((item) => item.month) || [];
      const months = [...prevMonth, ...nextMonth];
      setForecastingCategory(months);
      // When data is for over all service
      const prevSeries = ForecastingServiceData?.actual?.map((item) => item.spend) || [];
      const nextSeries = ForecastingServiceData?.forecast?.map((item) => item.spend) || [];
      const data = [...prevSeries, ...nextSeries];
      const overallData = [{
        name: 'cost',
        data,
      }];
      setForecastingOverallSeries(overallData);
      setForecastingSeries(overallData);
      // When data is for service wise
      const transformedPrevData = ForecastingServiceData?.actualDetail?.reduce((response, currentValue) => {
        const { serviceName, spend } = currentValue; if (!response[serviceName]) { response[serviceName] = { serviceName, data: [] }; } response[serviceName].data.push(spend.toFixed(2));
        return response;
      },
      {});
      const transformedNextData = ForecastingServiceData?.forecastDetail?.reduce((response, currentValue) => {
        const { serviceName, spend } = currentValue; if (!response[serviceName]) { response[serviceName] = { serviceName, data: [] }; } response[serviceName].data.push(spend.toFixed(2));
        return response;
      },
      {});
      if (transformedPrevData && transformedNextData) {
        const serviceData = Object.keys(transformedPrevData).map((serviceName) => {
          const item1 = transformedPrevData[serviceName];
          const item2 = transformedNextData[serviceName];
          return {
            name: serviceName,
            data: (item1?.data || []).concat(item2?.data || []),
          };
        });
        setForecastingServiceSeries(serviceData);
      }
      setLoading(true);
    }
    if (ForecastingServiceData?.length === 0 && !ForecastingServiceDataLoading) {
      setForecastingSeries([]);
      setLoading(true);
    }
  }, [ForecastingServiceData]);

  useEffect(() => {
    setSelectedMenu('servicetrendingtool');
  }, []);

  function onSelectServices(services) {
    if (tab === 'forecastAnalytics') {
      const filterByServices = services !== undefined ? forecastingServiceSeries.filter((x) => services?.includes(x.name)) : forecasting0verallSeries;
      setForecastingSeries(filterByServices);
    } else {
      const filterByServices = services !== undefined ? series.filter((x) => services?.includes(x.name)) : series;
      setProducts(filterByServices);
    }
  }
  function onSelectAnalytics(select) {
    setForecastingSeries(select === 'overall' ? forecasting0verallSeries : forecastingServiceSeries.slice(0, 50));
  }

  function onRangeSelect(range) {
    switch (tab) {
      case 'serviceTrending':
        if (range === 'noRange') { setTrendingSeries(trendingData); } else {
          setTrendingSeries(trendingData.slice(0, range));
        }
        break;
      case 'forecastAnalytics':
        if (range === 0) {
          setForecastingSeries(forecasting0verallSeries);
        } else {
          setForecastingSeries(forecastingServiceSeries.slice(0, range));
        }
        break;
      default:
        break;
    }
  }

  function ServiceTrendingTabs(tabName) {
    switch (tabName) {
      case 'serviceUsage':
        return ServiceAnalyticsDataLoading ? <Loader /> : <ServiceGraph category={category} products={products} range={range} />;
      case 'serviceTrending':
        return TrendingServiceDataLoading || TrendingServiceCumulativeDataLoading ? <Loader /> : (
          <TrendingServiceGraph
            serviceSeries={trendingSeries}
            serviceCategory={trendingCategory}
            cumulativeSeries={trendingCumulativeSeries}
            cumulativeCategory={trendingCumulativeCategory}
          />
        );
      case 'forecastAnalytics':
        return ForecastingServiceDataLoading ? <Loader /> : (
          <ForecastAnalytics
            data={forecastingSeries}
            category={forecastingCategory}
            actualCost={ForecastingServiceData?.actualTotal}
            forecastCost={ForecastingServiceData?.forecastTotal}
            pastMonths={ForecastingServiceData?.actual?.length}
            futureMonths={ForecastingServiceData?.forecast?.length}
          />
        );
      default:
        return '';
    }
  }

  return (
    <>
      <div className="wrapper">
        <div className="main">
          <div className="page-content">
            <PageHeader pageTitle="Service Trending Tool" />
            <Tabs
              onClick={(e) => setTab(e)}
              tabList={tabList}
              selected="serviceUsage"
            />

            <div className="tab-content" id="pills-tabContent">
              <ServiceFilter
                Type={(e) => setRange(e)}
                services={services}
                loading={() => setLoading(false)}
                onSelectServices={(e) => onSelectServices(e)}
                Tab={tab}
                onRangeSelect={(e) => onRangeSelect(e)}
                onSelectAnalytics={(e) => onSelectAnalytics(e)}
              />
              {loading ? (
                ServiceTrendingTabs(tab)
              ) : <Loader />}
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceTrendingTool;
