import React from 'react';

const CommonPopup = (props) => {
  const {
    size, popupTitle, action, close, hasAccess, btnValue, secBtn,
  } = props;

  return (
    <>
      <div className="modal fade show d-block" tabIndex="-1">
        <div className={`modal-dialog modal-${size}`}>
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">
                {popupTitle}
              </h1>
              <button type="button" className="btn-close" onClick={close} />
            </div>
            <div className="modal-body">
              {props.children}
            </div>
            <div className="modal-footer">
              {hasAccess
                ? <button type="button" className="btn btn-primary" onClick={action}>{btnValue}</button>
                : ''}
              <button type="button" className="btn btn-secondary" onClick={close}>{secBtn}</button>
              {close}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};

export default CommonPopup;
