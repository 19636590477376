import {
  GET_GRAPHDATA,
  ERROR_GET_GRAPHDATA,
  GET_TRENDING_SERVICE_DATA,
  ERROR_GET_TRENDING_SERVICE_DATA,
  GET_FORECASTING_SERVICE_DATA,
  ERROR_GET_FORECASTING_SERVICE_DATA,
  GET_TRENDING_SERVICE_CUMULATIVE_DATA,
  ERROR_GET_TRENDING_SERVICE_CUMULATIVE_DATA,
} from '../types';

const initialState = {
  ServiceAnalyticsData: [],
  ServiceAnalyticsDataLoading: true,
  ServiceAnalyticsDataError: '',
  TrendingServiceData: [],
  TrendingServiceDataLoading: true,
  TrendingServiceDataError: '',
  ForecastingServiceData: [],
  ForecastingServiceDataLoading: true,
  ForecastingServiceDataError: '',
  TrendingServiceCumulativeData: [],
  TrendingServiceCumulativeDataLoading: true,
  TrendingServiceCumulativeDataError: '',

};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GRAPHDATA:
      return {
        ...state,
        ServiceAnalyticsData: action.payload,
        ServiceAnalyticsDataLoading: false,
      };
    case ERROR_GET_GRAPHDATA:
      return {
        ...state,
        ServiceAnalyticsDataError: action.payload.message,
        ServiceAnalyticsDataLoading: false,
      };
    case GET_TRENDING_SERVICE_DATA:
      return {
        ...state,
        TrendingServiceData: action.payload,
        TrendingServiceDataLoading: false,
      };
    case ERROR_GET_TRENDING_SERVICE_DATA:
      return {
        ...state,
        TrendingServiceDataError: action.payload.message,
        TrendingServiceDataLoading: false,
      };
    case GET_TRENDING_SERVICE_CUMULATIVE_DATA:
      return {
        ...state,
        TrendingServiceCumulativeData: action.payload,
        TrendingServiceCumulativeDataLoading: false,
      };
    case ERROR_GET_TRENDING_SERVICE_CUMULATIVE_DATA:
      return {
        ...state,
        TrendingServiceCumulativeDataError: action.payload.message,
        TrendingServiceCumulativeDataLoading: false,
      };
    case GET_FORECASTING_SERVICE_DATA:
      return {
        ...state,
        ForecastingServiceData: action.payload,
        ForecastingServiceDataLoading: false,
      };
    case ERROR_GET_FORECASTING_SERVICE_DATA:
      return {
        ...state,
        ForecastingServiceDataError: action.payload.message,
        ForecastingServiceDataLoading: false,
      };
    default: return state;
  }
}
