import React from 'react';

const ModuleWidget = (props) => {
  const {
    title, navigationList, imgUrl,
  } = props;
  return (
    <div className="col-md-6">
      <div className="card text-center shadow rounded-4 mb-3 mb-md-0">
        <div className="card-body text-center">
          <div className="bg-icon">
            <img src={imgUrl} alt="image1" />
          </div>
          <h4>{title}</h4>
          <ul className="nav flex-column justify-content-center">
            {
                navigationList.map((item) => (
                  <li key={item}>{item}</li>
                ))
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ModuleWidget;
