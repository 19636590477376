import MUIDataTable from 'mui-datatables';
import { React } from 'react';

const AWSSkillBuilderMetricsTable = (props) => {
  const { data } = props;
  function getColumn() {
    const columns = [
      {
        name: 'employeeId',
        options: {
          download: true,
          filter: false,
          setCellHeaderProps: () => ({
            style: {
              position: 'sticky',
              background: '#f3f6f9',
              zIndex: 8,
            },
          }),
        },
      },
    ];
    columns.push({
      name: 'firstName',
      options: {
        download: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
      },
    });
    columns.push({
      name: 'lastName',
      options: {
        download: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
      },
    });
    columns.push({
      name: 'courseType',
      options: {
        download: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
      },
    });
    columns.push({
      name: 'courseName',
      options: {
        download: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
      },
    });
    columns.push({
      name: 'businessUnit',
      options: {
        download: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
      },
    });
    columns.push({
      name: 'progress',
      options: {
        download: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
      },
    });
    return [...columns];
  }

  const options = {
    filter: true,
    fixedHeader: true,
    fixedSelectColumn: true,
    responsive: 'scroll',
    rowsPerPage: 5,
    expandableRows: false,
    expandableRowsHeader: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    print: false,
    filterType: 'dropdown',
    sortOrder: {
      name: 'UploadedDate',
      sort: true,
      direction: 'desc',
    },
  };

  return (
    <div className="table-responsive">
      <MUIDataTable
        title="User Training Data"
        data={data}
        columns={getColumn()}
        options={options}
      />
    </div>
  );
};

export default AWSSkillBuilderMetricsTable;
