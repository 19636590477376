import React from 'react';
import ReactApexChart from 'react-apexcharts';

const LineChart = (props) => {
  const { series, options } = props;
  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={400}
      className="trending-service-graph"
    />
  );
};
export default LineChart;
