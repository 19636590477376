import {
  GET_MODULES, ERROR_GET_MODULES, RESET, DELETE_MODULE, ERROR_DELETE_MODULE,
} from '../types';

const initialState = {
  modules: [],
  modulesLoading: true,
  errorModules: '',
  deleteStatus: '',
  errorDeleteModules: '',
  deleteModuleLoading: true,
};
/* eslint-disable func-names */
export default function (state = initialState, action) {
  /* eslint-enable */
  switch (action.type) {
    case GET_MODULES:
      return {
        ...state,
        modules: action.payload,
        modulesLoading: false,
      };
    case ERROR_GET_MODULES:
      return {
        ...state,
        errorModules: action.payload.message,
        modulesLoading: false,
      };
    case RESET:
      return {
        ...state,
        errorModules: '',
      };
    case DELETE_MODULE:
      return {
        ...state,
        deleteStatus: action.payload,
        deleteModuleLoading: false,
      };
    case ERROR_DELETE_MODULE:
      return {
        ...state,
        errorDeleteModules: action.payload,
        deleteModuleLoading: false,
      };
    default: return state;
  }
}
