import React, {
  useState, useContext, useRef, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faUserShield,
  faFileInvoiceDollar,
  faCommentDots,
  faUserCog,
  faArrowLeft,
  faChartPie,
  faColumns,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from '@reach/router';
import { Context } from '../../Context/MenuContext';

const SideNavigator = () => {
  const [toggleSideNav, setToggleSideNav, selectedMenu, setSelectedMenu, selectedSubMenu, setSelectedSubMenu] = useContext(Context);
  const mobileMenuRef = useRef(null);
  const [adminSubOpen, setAdminSubOpen] = useState(false);
  const [serviceSubOpen, setServiceSubOpen] = useState(false);
  const [userManagmentSubOpen, setUserManagmentSubOpen] = useState(false);
  const [backArrow, setBackArrow] = useState(false);

  const employeeList = useSelector((state) => state.employeeList);
  const {
    userAccess,
  } = employeeList;

  const menuList = {
    admin: ['userAccess', 'moduleAccess', 'modules', 'newAnnouncement'],
    service: ['costReportingUpload', 'AWSAzureImageUpload', 'ProductRegionReport'],
    userManagement: ['employeeTraining', 'arcusVisitor'],
  };
  const hasAccess = (pageCode) => {
    if (userAccess?.length > 0) {
      return userAccess.filter((i) => i.pageCode?.toLowerCase() === pageCode?.toLowerCase() && (i.readAccess || i.writeAccess)).length > 0;
    }
    return false;
  };

  const mainMenuAccess = (menu) => {
    let haveAccess = false;
    Object.keys(menuList[menu]).forEach((o) => {
      if (hasAccess(menuList[menu][o])) {
        haveAccess = true;
      }
    });
    return haveAccess;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setToggleSideNav(false);
        setAdminSubOpen(false);
        setUserManagmentSubOpen(false);
        setServiceSubOpen(false);
        setBackArrow(false);
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  function handleNavToggle(menuNm) {
    switch (menuNm) {
      case 'home':
        setAdminSubOpen(false);
        setUserManagmentSubOpen(false);
        setServiceSubOpen(false);
        setSelectedSubMenu('');
        setSelectedMenu('home');
        break;
      case 'admin':
        setAdminSubOpen(!adminSubOpen);
        setUserManagmentSubOpen(false);
        setServiceSubOpen(false);
        if (serviceSubOpen || userManagmentSubOpen) {
          setToggleSideNav(false);
        }
        break;
      case 'service':
        setServiceSubOpen(!serviceSubOpen);
        setAdminSubOpen(false);
        setUserManagmentSubOpen(false);
        if (adminSubOpen || userManagmentSubOpen) {
          setToggleSideNav(false);
        }
        break;
      case 'feedback':
        setAdminSubOpen(false);
        setUserManagmentSubOpen(false);
        setServiceSubOpen(false);
        setSelectedSubMenu('');
        setSelectedMenu('feedback');
        break;
      case 'userManagement':
        setAdminSubOpen(false);
        setServiceSubOpen(false);
        setUserManagmentSubOpen(!userManagmentSubOpen);
        if (adminSubOpen || serviceSubOpen) {
          setToggleSideNav(false);
        }
        break;
      case 'servicetrendingtool':
        setAdminSubOpen(false);
        setUserManagmentSubOpen(false);
        setServiceSubOpen(false);
        setSelectedSubMenu('');
        setSelectedMenu('servicetrendingtool');
        break;
      case 'back':
        setToggleSideNav(true);
        setBackArrow(false);
        break;
      default:
        setAdminSubOpen(false);
        setUserManagmentSubOpen(false);
        setServiceSubOpen(false);
        setSelectedSubMenu('');
        setSelectedMenu('');
    }
  }
  return (
    <>
      <div className={`sidebar  ${toggleSideNav || backArrow ? 'show shadow' : ''}`} ref={mobileMenuRef}>
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link
              to="/"
              className="nav-item"
              onClick={() => handleNavToggle('home')}
            >
              <div className={`nav-link ${selectedMenu === 'home' ? 'active' : ''}`}>
                <span className="nav-icon">
                  <FontAwesomeIcon icon={faHome} />
                </span>
                Dashboard
              </div>
            </Link>
          </li>
          {
            mainMenuAccess('admin')
              ? (
                <li className="nav-item">
                  <div
                    className="nav-item"
                    onClick={() => handleNavToggle('admin')}
                  >
                    <div className={`nav-link ${selectedMenu === 'admin' || adminSubOpen ? 'show' : ''} ${(selectedSubMenu === 'userAccess') || (selectedSubMenu === 'modules') || (selectedSubMenu === 'newAnnouncement') ? 'active' : ''}`}>
                      <span className="nav-icon">
                        <FontAwesomeIcon icon={faUserShield} />
                      </span>
                      Admin
                      <ul className={`sub-nav shadow nav flex-column ${adminSubOpen ? 'show' : ''}`}>
                        <li className="nav-item">
                          <div className="back-arrow d-flex align-items-center mb-2" type="button" onClick={() => handleNavToggle('back')}>
                            <span className="px-2">
                              <FontAwesomeIcon icon={faArrowLeft} />
                            </span>
                            <h4 className="mb-0">Admin</h4>
                          </div>
                          {
                            hasAccess('userAccess')
                              ? (
                                <Link
                                  to="/userAccess"
                                  className={`nav-link ${selectedSubMenu === 'userAccess' ? 'active' : ''}`}
                                  onClick={() => { setSelectedSubMenu('userAccess'); setSelectedMenu('admin'); }}
                                >
                                  User Access
                                </Link>
                              )
                              : ''
                          }
                          {
                            hasAccess('moduleAccess')
                              ? (
                                <Link
                                  to="/moduleAccess"
                                  className={`nav-link ${selectedSubMenu === 'moduleAccess' ? 'active' : ''}`}
                                  onClick={() => { setSelectedSubMenu('moduleAccess'); setSelectedMenu('admin'); }}
                                >
                                  Module Access
                                </Link>
                              )
                              : ''
                          }
                          {
                            hasAccess('modules')
                              ? (
                                <Link
                                  to="/modules"
                                  className={`nav-link ${selectedSubMenu === 'modules' ? 'active' : ''}`}
                                  onClick={() => { setSelectedSubMenu('modules'); setSelectedMenu('admin'); }}
                                >
                                  Modules
                                </Link>
                              )
                              : ''
                          }
                          {
                            hasAccess('newAnnouncement')
                              ? (
                                <Link
                                  to="/newAnnouncement"
                                  className={`nav-link ${selectedSubMenu === 'newAnnouncement' ? 'active' : ''}`}
                                  onClick={() => { setSelectedSubMenu('newAnnouncement'); setSelectedMenu('admin'); }}
                                >
                                  New Announcements
                                </Link>
                              )
                              : ''
                          }
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              )
              : ''
          }
          {
            mainMenuAccess('service')
              ? (
                <li className="nav-item">
                  <div
                    className="nav-item"
                    onClick={() => handleNavToggle('service')}
                  >
                    <div className={`nav-link ${selectedMenu === 'service' || serviceSubOpen ? 'show' : ''} ${selectedSubMenu === 'costReportingUpload' || selectedSubMenu === 'awsAzureImage' ? 'active' : ''}`}>
                      <span className="nav-icon">
                        <FontAwesomeIcon icon={faFileInvoiceDollar} />
                      </span>
                      Service
                      <ul className={`sub-nav shadow nav flex-column ${serviceSubOpen ? 'show' : ''}`}>
                        <li className="nav-item">
                          <div className="back-arrow d-flex align-items-center mb-2" type="button" onClick={() => handleNavToggle('back')}>
                            <span className="px-2">
                              <FontAwesomeIcon icon={faArrowLeft} />
                            </span>
                            <h4 className="mb-0">Service</h4>
                          </div>
                          {
                            hasAccess('costReportingUpload')
                              ? (
                                <Link
                                  to="/costReportingUpload"
                                  className={`nav-link ${selectedSubMenu === 'costReportingUpload' ? 'active' : ''}`}
                                  onClick={() => { setSelectedSubMenu('costReportingUpload'); setSelectedMenu('service'); }}
                                >
                                  Cost Report File Upload
                                </Link>
                              )
                              : ''
                          }
                          {
                            hasAccess('aWSAzureImageUpload')
                              ? (
                                <Link
                                  to="/AWSAzureImageUpload"
                                  className={`nav-link ${selectedSubMenu === 'awsAzureImage' ? 'active' : ''}`}
                                  onClick={() => { setSelectedSubMenu('awsAzureImage'); setSelectedMenu('service'); }}
                                >
                                  Azure / AWS Image Upload
                                </Link>
                              )
                              : ''
                          }
                          {
                            hasAccess('productandRegionReportDownload')
                              ? (
                                <Link
                                  to="/ProductRegionReport"
                                  className={`nav-link ${selectedSubMenu === 'ProductRegionReport' ? 'active' : ''}`}
                                  onClick={() => { setSelectedSubMenu('ProductRegionReport'); setSelectedMenu('service'); }}
                                >
                                  Product and Region Report Download
                                </Link>
                              )
                              : ''
                          }
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              )
              : ''
          }
          {
            hasAccess('feedback')
              ? (
                <li className="nav-item">
                  <Link
                    to="/feedback"
                    className="nav-item"
                    onClick={() => handleNavToggle('feedback')}
                  >
                    <div className={`nav-link ${selectedMenu === 'feedback' ? 'active' : ''}`}>
                      <span className="nav-icon">
                        <FontAwesomeIcon icon={faCommentDots} />
                      </span>
                      Feedback
                    </div>
                  </Link>
                </li>
              )
              : ''
          }
          {
            mainMenuAccess('userManagement')
              ? (
                <li className="nav-item">
                  <div
                    className="nav-item"
                    onClick={() => handleNavToggle('userManagement')}
                  >
                    <div className={`nav-link ${selectedMenu === 'userManagement' || userManagmentSubOpen ? 'show' : ''} ${selectedSubMenu === 'employeeTraining' || selectedSubMenu === 'arcusVisitors' ? 'active' : ''}`}>
                      <span className="nav-icon">
                        <FontAwesomeIcon icon={faUserCog} />
                      </span>
                      User Management
                      <ul className={`sub-nav shadow nav flex-column ${userManagmentSubOpen ? 'show' : ''}`}>
                        <li className="nav-item">
                          <div className="back-arrow d-flex align-items-center mb-2" onClick={() => handleNavToggle('back')}>
                            <span className="px-2">
                              <FontAwesomeIcon icon={faArrowLeft} />
                            </span>
                            <h4 className="mb-0">User Management</h4>
                          </div>
                          {
                            hasAccess('employeeTraining')
                              ? (
                                <Link
                                  to="/employeeTraining"
                                  className={`nav-link ${selectedSubMenu === 'employeeTraining' ? 'active' : ''}`}
                                  onClick={() => { setSelectedSubMenu('employeeTraining'); setSelectedMenu('userManagement'); }}
                                >
                                  Employee Training
                                </Link>
                              )
                              : ''
                          }
                          {
                            hasAccess('arcusVisitor')
                              ? (
                                <Link
                                  to="/arcusVisitors"
                                  className={`nav-link ${selectedSubMenu === 'arcusVisitors' ? 'active' : ''}`}
                                  onClick={() => { setSelectedSubMenu('arcusVisitors'); setSelectedMenu('userManagement'); }}
                                >
                                  Arcus Visitors History
                                </Link>
                              )
                              : ''
                          }
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              )
              : ''
          }
          {
            hasAccess('awsskillbuildermetrics')
              ? (
                <li className="nav-item">
                  <Link
                    to="/awsskillbuildermetrics"
                    className="nav-item"
                    onClick={() => handleNavToggle('awsskillbuildermetrics')}
                  >
                    <div className={`nav-link ${selectedMenu === 'awsskillbuildermetrics' ? 'active' : ''}`}>
                      <span className="nav-icon">
                        <FontAwesomeIcon icon={faColumns} />
                      </span>
                      AWS Skill Builder
                    </div>
                  </Link>
                </li>
              ) : ''
          }
          {
            hasAccess('serviceTrendingTool')
              ? (
                <li className="nav-item">
                  <Link
                    to={hasAccess('serviceTrendingTool') ? '/servicetrendingtool' : '/accessDenied'}
                    className="nav-item"
                    onClick={() => handleNavToggle('servicetrendingtool')}
                  >
                    <div className={`nav-link ${selectedMenu === 'servicetrendingtool' ? 'active' : ''}`}>
                      <span className="nav-icon">
                        <FontAwesomeIcon icon={faChartPie} />
                      </span>
                      Service Trending Tool
                    </div>
                  </Link>
                </li>
              ) : ''
          }
        </ul>
      </div>
    </>
  );
};
export default SideNavigator;
