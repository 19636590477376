import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faInfoCircle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';

const Alert = (props) => {
  const { alertClass, message, onClose } = props;

  function displayIcon() {
    switch (alertClass) {
      case 'light-danger':
        return (
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="h3 me-2"
            style={{ color: '#721c24' }}
          />
        );
      case 'info':
        return (
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="h3 me-2"
            style={{ color: '#004085' }}
          />
        );
      case 'success':
        return (
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="h3 me-2"
            style={{ color: '#0c5460' }}
          />
        );
      default:
        return null;
    }
  }
  return (
    <div className="alert alert-danger alert-dismissible fade show" role="alert">
      <div className="d-flex align-items-center">
        {displayIcon()}
        {' '}
        {message}

      </div>
      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => onClose('')} />
    </div>
  );
};

export default Alert;
