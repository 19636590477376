import React, { useState } from 'react';

export const Context = React.createContext();

const initialState = {
  selectedTime: 'All time',
  selectecTimeVal: 0,
  selectedLengthNew: 0,
  selectedLengthAction: 0,
  selectedPercentNew: 0,
  selectedPercentAction: 0,
  reload: false,
  reloadOpenTab: false,
  card: 'open',
  contextFeedbackStatusRatio: [],
  contextFeedbackStatusRatioLoading: true,
  errorContextFeedbackStatusRatio: '',
};

export const FeedbackContext = ({ children }) => {
  const [feedbackContextState, setFeedbackContextState] = useState(initialState);
  return (
    <Context.Provider value={[feedbackContextState, setFeedbackContextState]}>
      {children}
    </Context.Provider>
  );
};
