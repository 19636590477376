import {
  COST_REPORT_UPLOAD_MESSAGE,
  ERROR_COST_REPORT_UPLOAD_MESSAGE,
  UPLOADED_FILES,
  ERROR_UPLOADED_FILES,
  RESET,
} from '../types';

const initialState = {
  CostReportUploadMessage: '',
  CostReportUploadLoading: true,
  CostReportUploadMessageError: '',
  uploadedFiles: [],
  uploadedFilesLoading: true,
  uploadedFilesError: '',

};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case COST_REPORT_UPLOAD_MESSAGE:
      return {
        ...state,
        CostReportUploadMessage: action.payload,
        CostReportUploadLoading: false,
      };
    case ERROR_COST_REPORT_UPLOAD_MESSAGE:
      return {
        ...state,
        CostReportUploadMessageError: action.payload.message,
        CostReportUploadLoading: false,
      };
    case UPLOADED_FILES:
      return {
        ...state,
        uploadedFiles: action.payload,
        uploadedFilesLoading: false,
      };
    case ERROR_UPLOADED_FILES:
      return {
        ...state,
        uploadedFilesError: action.payload.message,
        uploadedFilesLoading: false,
      };
    case RESET:
      return {
        ...state,
        CostReportUploadMessage: '',
        CostReportUploadLoading: true,
        CostReportUploadMessageError: '',
      };
    default: return state;
  }
}
