import {
  GET_AWS_SKILL_BUILDER_METRICS_USER_LIST,
  GET_AWS_SKILL_BUILDER_METRICS_USER_LIST_ERROR,
} from '../types';

const initialState = {
  skillBuilderMatricsData: [],
  skillBuilderMatricsLoading: true,
  errorSkillBuilderMatrics: '',
};

/* eslint-disable func-names */
export default function (state = initialState, action) {
  /* eslint-enable */
  switch (action.type) {
    case GET_AWS_SKILL_BUILDER_METRICS_USER_LIST:
      return {
        ...state,
        skillBuilderMatricsData: action.payload,
        skillBuilderMatricsLoading: false,
      };
    case GET_AWS_SKILL_BUILDER_METRICS_USER_LIST_ERROR:
      return {
        ...state,
        skillBuilderMatricsLoading: false,
        errorSkillBuilderMatrics: action.payload.message,
      };
    default: return state;
  }
}
