import { React } from 'react';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

const ServiceTrendingToolCard = (props) => {
  const {
    title, amount, percent, amountDiff, type, additionalClass,
  } = props;

  return (
    <>
      <div className={`service-trending-tool-card ${additionalClass}`}>
        <div className="card">
          <div className="card-body">
            <p className="h6 text-muted text-uppercase mb-0">
              {title}
            </p>
            <p className="h1 my-1 my-sm-4">{amount}</p>
            <div className="d-flex">
              {percent
                && (
                  <p className={`${type === 'success' ? 'text-success' : 'text-danger'} mb-0`}>
                    {percent !== 0 ? <FontAwesomeIcon icon={type === 'success' ? faArrowUp : faArrowDown} /> : ''}
                    {' '}
                    {percent < 0 ? percent * -1 : percent}
                    {percent === 0 ? '' : '%'}
                  </p>
                )}
              {amountDiff && (
                <p className="small text-muted mb-0 ms-2">
                  {amountDiff}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceTrendingToolCard;
