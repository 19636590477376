import {
  React, useContext, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { Context as MenuContext } from '../Context/MenuContext';
import PageHeader from '../Components/Common/PageHeader';
import ScoreCard from '../Components/Common/ScoreCard';
import AWSSkillBuilderMetricsTable from '../Components/AWSSkillBuilderMetrics/AWSSkillBuilderMetricsTable';
import AWSSkillBuilderMetricsFilter from '../Components/AWSSkillBuilderMetrics/AWSSkillBuilderMetricsFilter';
import Loader from '../Components/Loader';
import Alert from '../Components/Common/Alert';

import { getSkillBuilderMetricsData } from '../Store/actions/skillBuilderAction';

const AWSSkillBuilderMetrics = () => {
  const filterObject = {
    courseType: 'all',
    businessUnit: 'all',
  };
  const [trainingData, setTrainingData] = useState([]);
  const [trainingTypes, setTrainingTypes] = useState(null);
  const [businessUnits, setBusinessUnits] = useState(null);
  const [businessUnitsCount, setBusinessUnitsCount] = useState(0);
  const [ShowErrMsg, setShowErrMsg] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [filter, setFilter] = useState(filterObject);

  const dispatch = useDispatch();
  const skillbuilder = useSelector((state) => state.skillBuilderMetrics);
  const {
    skillBuilderMatricsData,
    skillBuilderMatricsLoading, errorSkillBuilderMatrics,
  } = skillbuilder;

  const [, , , setSelectedMenu] = useContext(MenuContext);

  useEffect(() => {
    setSelectedMenu('awsskillbuildermetrics');
  }, []);

  const apiCall = () => {
    const apiUrl = '/api/SkillBuilder/GetTrainingData';
    dispatch(getSkillBuilderMetricsData(apiUrl));
  };

  useEffect(() => {
    apiCall();
  }, [dispatch]);

  useEffect(() => {
    if (errorSkillBuilderMatrics !== '') {
      setErrorMessage(errorSkillBuilderMatrics);
      setShowErrMsg(true);
    }
  }, [errorSkillBuilderMatrics]);

  function findUniquevalues(data, col) {
    const uniqueValue = data.reduce((acc, obj) => {
      if (!acc.includes(obj[col])) {
        acc.push(obj[col]);
      }
      return acc;
    }, []);
    return uniqueValue;
  }

  const handleChange = (e, name) => {
    const { value } = e;
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  useEffect(() => {
    const resultData = skillBuilderMatricsData.filter((item) => {
      const matchesCourseType = filter.courseType === 'all' || item.courseType === filter.courseType;
      const matchesBusinessType = filter.businessUnit === 'all' || item.businessUnit === filter.businessUnit;
      return matchesCourseType && matchesBusinessType;
    });
    setTrainingData(resultData);
    const businessUnit = findUniquevalues(resultData, 'businessUnit');
    setBusinessUnitsCount(businessUnit.length);
  }, [filter]);

  useEffect(() => {
    setTrainingData(skillBuilderMatricsData);
    const courseType = findUniquevalues(skillBuilderMatricsData, 'courseType');
    const businessUnit = findUniquevalues(skillBuilderMatricsData, 'businessUnit');
    setTrainingTypes(courseType);
    setBusinessUnits(businessUnit);
    setBusinessUnitsCount(businessUnit?.length);
  }, [skillBuilderMatricsData]);

  return (
    <>
      <div className="wrapper">
        <div className="main">
          { skillBuilderMatricsLoading
            ? (<Loader />)
            : (ShowErrMsg
              ? (
                <div className="col-12">
                  <Alert
                    message={errorMessage}
                    alertClass="light-danger"
                    onClose={() => setShowErrMsg(false)}
                  />
                </div>
              ) : (
                <>
                  <div className="page-content">
                    <PageHeader pageTitle="AWS Skill Builder" />
                    <AWSSkillBuilderMetricsFilter
                      trainingTypes={trainingTypes}
                      businessUnits={businessUnits}
                      handleChange={handleChange}
                    />
                    <div className="d-flex">
                      <ScoreCard icon={<FontAwesomeIcon icon={faUserFriends} />} title="Total Users" count={trainingData?.length} infoText="Total number of users getting training" />
                      <ScoreCard icon={<FontAwesomeIcon icon={faBriefcase} />} title="Business Units" count={businessUnitsCount} infoText="Total number of business units belong to users" />
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <AWSSkillBuilderMetricsTable data={trainingData} />
                      </div>
                    </div>
                  </div>

                </>
              ))}
        </div>
      </div>
    </>
  );
};

export default AWSSkillBuilderMetrics;
