import React, {
  useEffect, useState, useRef,
} from 'react';

const HeaderInfo = (props) => {
  const { headerName, info } = props;
  const [styleTheme, setStyleTheme] = useState({ display: 'none' });
  const [show, setShow] = useState(false);
  const infoBlockRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        infoBlockRef.current
        && !infoBlockRef.current.contains(event.target)
      ) {
        setStyleTheme({ display: 'none' });
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      setShow(false);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [infoBlockRef]);

  const handleOnInfoClick = () => {
    setShow(true);
    setStyleTheme({ display: 'block' });
  };

  return (
    <h3 className="card-label col p-0">
      <div className="d-flex align-items-center justify-content-start">
        <span>
          {headerName}
          {' '}
        </span>

        {info
          && (
            <span
              className="svg-icon svg-light ml-2 custom-tooltip-text"
              onClick={handleOnInfoClick}
            >
              <svg
                stroke="currentColor"
                className="showModal"
                id="showModal"
                fill="#3699ff"
                strokeWidth="0"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z"
                  clipRule="evenodd"
                />
                <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                <circle cx="8" cy="4.5" r="1" />
              </svg>
              {/* <!-- Hovered Info --> */}
              <div
                className="custom-tooltip-info showModalSection"
                id="showModalSection"
                style={styleTheme}
              >
                <span ref={infoBlockRef} className="d-inline-block" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content="Disabled popover">
                  {show && (
                    <div className="modal-dialog modal show d-block" tabIndex="-1">
                      <div className="modal-content">
                        {info}
                      </div>
                    </div>
                  )}
                </span>
              </div>
            </span>
          )}
      </div>
    </h3>
  );
};
export default HeaderInfo;
