import React from 'react';
import { faFileInvoiceDollar, faCommentDots, faUserShield } from '@fortawesome/free-solid-svg-icons';
import Activity from './Activity';

const Activities = () => {
  const data = [{
    title: 'Ascendon Cost Report', detail: 'Ascendon Cost Report has been uploaded by PCA Team', time: '13 hours ago', icon: faFileInvoiceDollar,
  },
  {
    title: 'Feedback', detail: 'A new feedback has been received by an anonymous user.', time: '12 hours ago', icon: faCommentDots,
  },
  {
    title: 'Admin Section', detail: 'Admin access provided to Bhadresh Arya by PCA Team', time: '14 hours ago', icon: faUserShield,
  }];
  return (
    <div className="card shadow-sm">
      <div className="card-body d-grid">
        <h5 className="mb-4">Latest Activity</h5>
        <div className="activity">
          {
            data.map((item) => (
              <Activity title={item.title} detail={item.detail} time={item.time} icon={item.icon} />
            ))
          }
          <p className="text-center mb-0">
            <a href="/">View More</a>
          </p>
        </div>
      </div>

    </div>
  );
};
export default Activities;
