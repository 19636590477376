import React, {
  useState, useContext, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewFeedbacks from '../Components/Feedback/NewFeedbacks';
import { getFeedbacks } from '../Store/actions/feedbackAction';
import DashboardFilter from '../Components/Filter/DashboardFilter';
import FeedbackScoreCard from '../Components/Feedback/FeedbackScoreCard';
import { Context } from '../Context/FeedbackContext';
import { Context as MenuContext } from '../Context/MenuContext';
import PageHeader from '../Components/Common/PageHeader';
import Alert from '../Components/Common/Alert';
import Loader from '../Components/Loader';
import Tabs from '../Components/Common/Tabs';
import FeedbackList from '../Components/Feedback/FeedbackList';

const Feedback = () => {
  const dispatch = useDispatch();
  const feedbackList = useSelector((state) => state.feedbackList);
  const { feedbacks, feedbackLoading, errorFeedback } = feedbackList;

  const employeeList = useSelector((state) => state.employeeList);
  const {
    userAccess,
  } = employeeList;

  const [feedbackContextState, setFeedbackContextState] = useContext(Context);
  const [, , , setSelectedMenu] = useContext(MenuContext);

  const [hasAccess, setHasAccess] = useState(false);
  const {
    selectedTime,
    selectedLengthNew,
    selectedLengthAction,
    reload,
    selectedPercentNew,
    selectedPercentAction,
  } = feedbackContextState;
  const [ShowErrMsg, setShowErrMsg] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [tab, setTab] = useState('open');
  const tabList = [
    { value: 'open', label: 'New' },
    { value: 'inprogress', label: 'Open' },
    { value: 'closed', label: 'Completed' },
    { value: 'archive', label: 'Archive' },
  ];

  useEffect(() => {
    if (userAccess?.length > 0
      && userAccess.filter((i) => i.pageCode === 'feedback' && i.writeAccess)?.length > 0) {
      setHasAccess(true);
    }
  }, []);

  const apiCall = () => {
    const apiUrl = '/api/feedback/GetFeedback/Open';
    dispatch(getFeedbacks(apiUrl));
  };
  useEffect(() => {
    setSelectedMenu('feedback');
  }, []);
  useEffect(() => {
    if (errorFeedback !== '') {
      setErrorMessage(errorFeedback);
      setShowErrMsg(true);
    }
  }, [errorFeedback]);
  useEffect(() => {
    apiCall();
  }, [dispatch]);

  useEffect(() => {
    if (reload) {
      apiCall();
      setFeedbackContextState({
        ...feedbackContextState,
        reload: false,
      });
    }
  }, [reload]);

  useEffect(() => {
    if (tab === 'open') { apiCall(); }
  }, [tab]);

  function feedbackShowTab(tabName) {
    switch (tabName) {
      case 'open':
        return <NewFeedbacks feedbacks={feedbacks} hasAccess={hasAccess} />;
      case 'inprogress':
        return <FeedbackList tab={tab} hasAccess={hasAccess} />;
      case 'closed':
        return <FeedbackList tab={tab} hasAccess={hasAccess} />;
      case 'archive':
        return <FeedbackList tab={tab} hasAccess={hasAccess} />;
      default:
        return '';
    }
  }
  return (
    <>
      {feedbackLoading ? (
        <Loader />
      ) : ShowErrMsg ? (
        <div className="col-12">
          <Alert
            message={errorMessage}
            alertClass="light-danger"
            onClose={() => setShowErrMsg(false)}
          />
        </div>
      ) : (
        <>
          <div className="wrapper">
            <div className="main">
              <PageHeader pageTitle="Feedback">
                <DashboardFilter />
              </PageHeader>
              <div className="page-content">
                <div className="dashboard-card">

                  <FeedbackScoreCard title="New Feedbacks" count={selectedLengthNew} percent={selectedPercentNew} duration={selectedTime} type={`${selectedPercentNew > 0 ? 'success' : 'danger'}`} />
                  <FeedbackScoreCard title="Feedbacks Action" count={selectedLengthAction} percent={selectedPercentAction} duration={selectedTime} type={`${selectedPercentAction > 0 ? 'success' : 'danger'}`} />
                </div>
                <Tabs
                  onClick={(e) => setTab(e)}
                  tabList={tabList}
                  selected="open"
                />

                <div className="tab-content" id="pills-tabContent">

                  {feedbackShowTab(tab)}

                </div>

              </div>

            </div>
          </div>
        </>
      )}
    </>

  );
};

export default Feedback;
