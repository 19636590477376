import React, { useState, useEffect } from 'react';
import Loader from '../../Loader';
import HeaderInfo from '../../Common/HeaderInfo';
import Tabs from '../../Common/Tabs';
import LineChart from '../../Common/Charts/LineChart';

const TrendingServiceGraph = (props) => {
  const {
    serviceSeries, serviceCategory, cumulativeSeries, cumulativeCategory,
  } = props;
  const [tab, setTab] = useState('serviceUsage');
  const [series, setSeries] = useState([]);
  const [category, setCategory] = useState(serviceCategory);
  const [chartOptions, setChartOptions] = useState();

  const [loading, setLoading] = useState(true);

  const tabList = [
    { value: 'serviceWise', label: 'Service Wise' },
    { value: 'CumulativeData', label: 'Cumulative Data' },
  ];

  const options = {
    series,
    chart: {
      height: 400,
      type: 'line',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    fill: {
      type: 'gradient',
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: category,
      labels: {
        text: 'Trending Services',
      },
    },
    yaxis: {
      labels: {
        formatter(value) {
          return value;
        },
      },
    },
    tooltip: {
      custom({
        series, seriesIndex, dataPointIndex, w,
      }) {
        const seriesName = w.config.series[seriesIndex].name;
        const value = series[seriesIndex][dataPointIndex];
        let difference = 0;
        if ('difference' in w.config.series[seriesIndex]) {
          difference = w.config.series[seriesIndex]?.difference[dataPointIndex];
        }
        return (
          '<div class="apexcharts-tooltip-custom">'
          + `<div class="apexcharts-tooltip-title"><strong>${seriesName}</strong></div>`
          + `<div class="p-2"><span><strong>Cost:</strong> ${value}</span><br/>`
          + '<span><strong>Difference:</strong> '
          + `${difference < 0
            ? '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="14" width="14" fill="red"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-143.6-28.9L288 302.6V120c0-13.3-10.7-24-24-24h-16c-13.3 0-24 10.7-24 24v182.6l-72.4-75.5c-9.3-9.7-24.8-9.9-34.3-.4l-10.9 11c-9.4 9.4-9.4 24.6 0 33.9L239 404.3c9.4 9.4 24.6 9.4 33.9 0l132.7-132.7c9.4-9.4 9.4-24.6 0-33.9l-10.9-11c-9.5-9.5-25-9.3-34.3 .4z"/></svg>'
            : '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="14" width="14" fill="green"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M8 256C8 119 119 8 256 8s248 111 248 248-111 248-248 248S8 393 8 256zm143.6 28.9l72.4-75.5V392c0 13.3 10.7 24 24 24h16c13.3 0 24-10.7 24-24V209.4l72.4 75.5c9.3 9.7 24.8 9.9 34.3 .4l10.9-11c9.4-9.4 9.4-24.6 0-33.9L273 107.7c-9.4-9.4-24.6-9.4-33.9 0L106.3 240.4c-9.4 9.4-9.4 24.6 0 33.9l10.9 11c9.6 9.5 25.1 9.3 34.4-.4z"/></svg>'}`
          + ` ${difference?.toFixed(2)}</span>`
          + '</div></div>'
        );
      },

      style: {
        fontSize: '10px',
      },
      hideEmptySeries: true,
    },
    legend: {
      position: 'right',
      fontSize: '10px',
      width: 200,
      labels: {
        text: 'Top Services',
      },
    },
  };

  useEffect(() => {
    // setLoading(true);
    if (tab === 'CumulativeData') {
      setCategory(cumulativeCategory);
      setChartOptions(options);
      setSeries(cumulativeSeries);
      setLoading(false);
    } else {
      setCategory(serviceCategory);
      setChartOptions(options);
      setSeries(serviceSeries);
      setLoading(false);
    }
  }, [tab, serviceSeries]);

  return (

    <div className="card h-100">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-start">
          <HeaderInfo headerName="Trending Services" info="Data shows the percent of increase and decrease in the usage of services on given date range" />
          <Tabs
            onClick={(e) => { setTab(e); setLoading(true); }}
            tabList={tabList}
            selected="serviceWise"
            size="sm"
          />
        </div>
      </div>
      {loading ? <Loader />
        : (
          <LineChart series={series} options={chartOptions} />
        )}
    </div>
  );
};

export default TrendingServiceGraph;
