import React, { useEffect, useState, useContext } from 'react';
import MUIDataTable from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';
import Alert from '../../Common/Alert';
import { getUploadedFileHistory } from '../../../Store/actions/CostReportAction';
import { Context } from '../../../Context/FileUploadContext';

const CostUploadedList = (props) => {
  const [tableData, setTableData] = useState([]);
  const [showError, setShowError] = useState(false);

  const [reloadFileHistory, setReloadFileHistory] = useContext(Context);
  const { type } = props;

  const dispatch = useDispatch();
  const costReportResult = useSelector((state) => state.costReportResult);
  const { uploadedFiles, uploadedFilesLoading, uploadedFilesError } = costReportResult;
  const options = {
    filter: true,
    fixedHeader: true,
    fixedSelectColumn: true,
    responsive: 'scroll',
    rowsPerPage: 5,
    expandableRows: false,
    expandableRowsHeader: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    print: false,
    filterType: 'dropdown',
    sortOrder: {
      name: 'UploadedDate',
      sort: true,
      direction: 'desc',
    },
  };

  function getColumn() {
    const columns = [
      {
        name: 'FileName',
        options: {
          download: true,
          filter: false,
          setCellHeaderProps: () => ({
            style: {
              position: 'sticky',
              background: '#f3f6f9',
              zIndex: 8,
            },
          }),
        },
      },
    ];
    columns.push({
      name: 'UploadedBy',
      options: {
        download: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
      },
    });
    columns.push({
      name: 'UploadedDate',
      options: {
        download: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
      },
    });
    return [...columns];
  }

  const apiCall = () => {
    const apiUrl = `/api/FileUpload/GetUploadedFileHistory/${type}`;
    dispatch(getUploadedFileHistory(apiUrl));
  };
  useEffect(() => {
    apiCall();
  }, [dispatch]);

  useEffect(() => {
    if (reloadFileHistory === 'reload') {
      apiCall();
      setReloadFileHistory('');
    }
  }, [reloadFileHistory]);

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      const s = uploadedFiles.map((item) => (
        {
          FileName: item.fileName,
          uploadedBy: item.uploadedBy,
          UploadedDate: item.uploadedDate.substring(0, 10),
        }
      ));
      setTableData(s.map(Object.values));
    } else {
      setTableData([]);
    }
  }, [uploadedFiles, uploadedFilesLoading]);

  useEffect(() => {
    if (uploadedFilesError !== '') {
      setShowError(true);
    }
  }, [uploadedFilesLoading, uploadedFiles, uploadedFilesError]);
  return (
    <>
      {uploadedFilesLoading ? <Loader /> : (
        <>
          { uploadedFilesError !== '' && showError ? (

            <Alert
              message={uploadedFilesError}
              alertClass="light-danger"
              onClose={() => setShowError(false)}
            />
          ) : (
            <div className="page-content">
              <div className="table-responsive">
                <MUIDataTable
                  title="Uploaded File History"
                  data={tableData}
                  columns={getColumn()}
                  options={options}
                />
              </div>
            </div>
          )}
        </>
      )}

    </>
  );
};

export default CostUploadedList;
