import React, { useState } from 'react';

export const Context = React.createContext();

export const MenuContext = ({ children }) => {
  const [toggleSideNav, setToggleSideNav] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('home');
  const [selectedSubMenu, setSelectedSubMenu] = useState('');

  return (
    <Context.Provider value={[toggleSideNav, setToggleSideNav, selectedMenu, setSelectedMenu, selectedSubMenu, setSelectedSubMenu]}>
      {children}
    </Context.Provider>
  );
};
