import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { saveAndUpdateAnnouncement } from '../../../Store/actions/announcementAction';
import Select from '../../Common/Select';

const Popup = (props) => {
  const {
    hasAccess, onClose, row, type, apiCall,
  } = props;
  const defaultAnnouncement = () => ({
    Id: 0,
    Header: '',
    Status: true,
    Paragraph: '',
    Url: '',
    Type: '',
  });
  const dispatch = useDispatch();
  const newAnnouncement = useSelector((state) => state.newAnnouncement);
  const { announcementStatus, announcementLoading, announcementError } = newAnnouncement;
  const [announcementResults, setAnnouncementResults] = useState(defaultAnnouncement());
  const [header, setHeader] = useState('');
  const [paragraph, setParagraph] = useState('');
  const [url, setUrl] = useState('');
  const [linkType, setLinkType] = useState('');
  const [status, setStatus] = useState('');

  const announcementFieldChange = (e) => {
    const { target } = e;
    const { value } = target;
    const { name } = target;
    if (name === 'header') {
      setHeader(value);
      setAnnouncementResults(
        {
          ...announcementResults,
          Header: value,
        },
      );
    }
    if (name === 'Status') {
      setAnnouncementResults(
        {
          ...announcementResults,
          Status: value,
        },
      );
    }
    if (name === 'Paragraph') {
      setParagraph(value);
      setAnnouncementResults(
        {
          ...announcementResults,
          Paragraph: value,
        },
      );
    }
    if (name === 'Url') {
      setUrl(value);
      setAnnouncementResults(
        {
          ...announcementResults,
          Url: value,
        },
      );
    }
    if (name === 'Type') {
      setLinkType(value);
      setAnnouncementResults(
        {
          ...announcementResults,
          Type: value,
        },
      );
    }
    if (name === 'Status') {
      setStatus(value);
      setAnnouncementResults(
        {
          ...announcementResults,
          Status: value === 'Activate',
        },
      );
    }
  };

  useEffect(() => {
    if (type === 'Edit') {
      setHeader(row[1]);
      setParagraph(row[2]);
      setUrl(row[3]);
      setLinkType(row[4]);
      setAnnouncementResults(
        {
          Id: row[0],
          Header: row[1],
          Paragraph: row[2],
          Url: row[3],
          Type: row[4],
          Status: row[5],
        },
      );
    }
  }, []);
  const reload = () => {
    apiCall();
    onClose();
  };

  const submit = () => {
    const apiUrl = '/api/announcement/SaveAndUpdateAnnouncements';
    dispatch(saveAndUpdateAnnouncement(apiUrl, announcementResults));
  };

  useEffect(() => {
    if (announcementStatus === 'success') {
      toast.success('Announcement successfully added!');
      dispatch({ type: 'RESET' });
      reload();
    } else if (announcementError !== '') {
      toast.error(announcementError);
      dispatch({ type: 'RESET' });
      reload();
    }
    dispatch({ type: 'RESET' });
  }, [announcementStatus, announcementLoading, announcementError]);

  return (
    <>
      <div className="modal fade show d-block" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">
                New Announcement
              </h1>
              <button type="button" className="btn-close" onClick={onClose} />
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label className="form-label">Announcement Title</label>
                  <input type="text" className="form-control" placeholder="Enter Announcement Title" name="header" onChange={announcementFieldChange} value={header} />
                </div>
                <div className="mb-3">
                  <label className="form-label">Announcement Description</label>
                  <textarea className="form-control" placeholder="Enter Announcement Description" name="Paragraph" onChange={announcementFieldChange} value={paragraph} />
                </div>
                <div className="mb-3">
                  <label className="form-label">Link</label>
                  <input type="text" className="form-control" placeholder="Enter Link" name="Url" onChange={announcementFieldChange} value={url} />
                </div>
                <div className="mb-3">
                  <Select
                    name="Type"
                    title="Link Type"
                    value={linkType}
                    placeholder="Pick One..."
                    options={{ Internal: 'Internal', External: 'External' }}
                    onChange={announcementFieldChange}
                    required
                  />
                </div>

                <div className="mb-3">
                  <Select
                    name="Status"
                    title="Status"
                    value={status}
                    placeholder="Pick One..."
                    options={{ Activate: 'Activate', Deactivate: 'Deactivate' }}
                    onChange={announcementFieldChange}
                    required
                  />
                </div>

              </form>
              <div className="modal-footer">
                {hasAccess
                  ? <button type="button" className="btn btn-primary" onClick={submit}>Submit</button>
                  : ''}
                <button type="button" className="btn btn-secondary" onClick={onClose}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};

export default Popup;
