import axios from 'axios';
import {
  GET_AWS_SKILL_BUILDER_METRICS_USER_LIST,
  GET_AWS_SKILL_BUILDER_METRICS_USER_LIST_ERROR,
} from '../types';

// eslint-disable-next-line import/prefer-default-export
export const getSkillBuilderMetricsData = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_AWS_SKILL_BUILDER_METRICS_USER_LIST,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_AWS_SKILL_BUILDER_METRICS_USER_LIST_ERROR,
      payload: error,
    });
  }
};
