import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './signIn.css';

const SignIn = () => {
  const [pageName, setPageName] = useState('');

  useEffect(() => {
    const signOutTime = localStorage.getItem('configToolSignOutTime');
    const signOutDuration = new Date(signOutTime).getHours() * 60 + new Date(signOutTime).getMinutes() + (new Date(signOutTime).getSeconds() / 60);
    const currentDuration = new Date().getHours() * 60 + new Date().getMinutes() + (new Date().getSeconds() / 60);
    if ((currentDuration - signOutDuration) < 1) {
      setPageName(localStorage.getItem('configToolPage'));
    } else {
      localStorage.setItem('configToolPage', 'signin');
    }
  }, []);
  return (
    <div className="main">
      <div className="row justify-content-center">
        <div className="col-md-4">
          <div className="card shadow text-center mt-5">
            <div className="card-body p-5">
              <h1 className="mb-0">Arcus</h1>
              <h3>Configuration Tool</h3>
              {pageName === 'signout' ? (
                <p className="logout-msg">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="mr-3"
                  />
                  {' '}
                  You are logged out successfully
                </p>
              ) : ''}
              <p className="lead my-4">
                {`Sign in ${pageName === 'signout' ? 'again' : ''} to get started.`}
              </p>
              <a href="/api/auth/signin" className="btn btn-primary text-white">
                <FontAwesomeIcon icon={faSignOutAlt} className="me-1" />
                Sign in using SSO
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignIn;
