import React, { useContext, useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Context } from '../Context/MenuContext';
import PageHeader from '../Components/Common/PageHeader';
import Loader from '../Components/Loader';
import CommonPopup from '../Components/Common/CommonPopup';

const ModuleAccess = () => {
  const [, , , setSelectedMenu, , setSelectedSubMenu] = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [popupLoading, setPopupLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState('');
  const [tableData, setTableData] = useState([]);
  const [modules, setModules] = useState([]);
  const [userList, setUserList] = useState([]);

  const openPopup = (rowIndex) => {
    setPopupLoading(true);
    axios.get(
      `/api/module/GetUserAcessList/${rowIndex.rowData[0]}`,
    )
      .then((response) => {
        setUserList(response.data);
        setPopupLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
    setPopupTitle(rowIndex.rowData[2]);
    setShowPopup(true);
  };

  useEffect(() => {
    axios.get('/api/module/GetModules')
      .then((response) => {
        setModules(response.data);
        setLoading(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (modules.length > 0) {
      const s = modules.map((item) => (
        {
          moduleId: item.id,
          application: item.application,
          moduleName: item.moduleName,
          pageName: item.pageName,
          pageCode: item.pageCode,
        }
      ));
      setTableData(s.sort((a, b) => b.moduleId - a.moduleId).map(Object.values));
    } else {
      setTableData([]);
    }
  }, [modules]);

  useEffect(() => {
    setSelectedSubMenu('moduleAccess');
    setSelectedMenu('admin');
  }, []);

  function getColumn() {
    const columns = [
      {
        name: 'Id',
        options: {
          display: false,
          download: true,
          filter: false,
          print: false,
          viewColumns: false,
        },
      },
    ];
    columns.push({
      name: 'Application',
      options: {
        download: true,
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
        setCellProps: () => ({
          style: {
            padding: '0 2rem',
          },
        }),
      },
    });
    columns.push({
      name: 'Module',
      options: {
        download: true,
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
        setCellProps: () => ({
          style: {
            padding: '0 2rem',
          },
        }),
      },
    });
    columns.push({
      name: 'Page',
      options: {
        download: true,
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
        setCellProps: () => ({
          style: {
            padding: '0 2rem',
          },
        }),
      },
    });
    columns.push({
      name: 'View',
      options: {
        download: false,
        filter: false,
        viewColumns: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
            textAlign: 'center',
          },
        }),

        customBodyRender: (dataIndex, rowIndex) => (
          <div className="text-center">
            <button
              type="button"
              className="btn text-primary me-1"
              onClick={() => openPopup(rowIndex)}
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
          </div>
        ),
      },
    });
    return [...columns];
  }

  const options = {
    filter: true,
    fixedHeader: true,
    fixedSelectColumn: true,
    responsive: 'scroll',
    rowsPerPage: 5,
    expandableRows: false,
    expandableRowsHeader: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    print: false,
    filterType: 'dropdown',
    sortOrder: {
      name: 'UploadedDate',
      sort: true,
      direction: 'desc',
    },
  };

  return (
    <div className="wrapper">
      <div className="main">
        <div className="row">
          <div className="col-lg-12">
            <PageHeader pageTitle="Module Access" />
            <div className="page-content">
              <div className="card shadow">
                <div className="card-body">
                  <>
                    {loading
                      ? (
                        <div className="table-responsive">
                          <MUIDataTable
                            columns={getColumn()}
                            options={options}
                            data={tableData}
                          />
                        </div>
                      ) : (
                        <Loader />
                      )}
                  </>
                </div>
              </div>
            </div>
            {showPopup ? (
              popupLoading ? (
                <Loader />
              ) : (
                <CommonPopup
                  popupTitle={popupTitle}
                  action={() => setShowPopup(false)}
                  close={() => setShowPopup(false)}
                  hasAccess={false}
                  btnValue="Save Changes"
                  secBtn="Close"
                >
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm mb-0">
                      <thead className="table-light text-center">
                        <tr>
                          <th>User</th>
                          <th>Read Access</th>
                          <th>Write Access</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userList.map((item) => (
                          <tr key={item.id}>
                            <td>
                              {item.employeeName}
                            </td>
                            <td className="text-center">
                              <input type="checkbox" checked={item.readAccess} className="form-check-input" />
                            </td>
                            <td className="text-center">
                              <input type="checkbox" checked={item.writeAccess} className="form-check-input" />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CommonPopup>
              )
            ) : ''}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModuleAccess;
