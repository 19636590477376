import axios from 'axios';
import {
  GET_EMPLOYEE,
  ERROR_GET_EMPLOYEE,
  GET_USER_MODULES_ACCESS,
  ERROR_GET_USER_MODULES_ACCESS,
  GET_USER_ACCESS,
  ERROR_GET_USER_ACCESS,
  SAVE_USER_ACCESS_MODULES,
  ERROR_SAVE_USER_ACCESS_MODULES,
} from '../types';

export const getEmployee = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_EMPLOYEE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_GET_EMPLOYEE,
      payload: error,
    });
  }
};
export const getUserAccessModule = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_USER_MODULES_ACCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_GET_USER_MODULES_ACCESS,
      payload: error,
    });
  }
};
export const getUserAccess = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_USER_ACCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_GET_USER_ACCESS,
      payload: error,
    });
  }
};
export const saveAndUpdateUserAccess = (apiUrl, payload) => async (dispatch) => {
  try {
    const res = await axios.post(apiUrl, payload);
    dispatch({
      type: SAVE_USER_ACCESS_MODULES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_SAVE_USER_ACCESS_MODULES,
      payload: error,
    });
  }
};
