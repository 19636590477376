import React, {
  useState, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import PermissionPopup from '../PermissionPopup';
import { getEmployee } from '../../../Store/actions/userAccessAction';
import Alert from '../../Common/Alert';

const Search = () => {
  const [searchInput, setSearchInput] = useState('');
  const dispatch = useDispatch();
  const employeeList = useSelector((state) => state.employeeList);
  const { employees, errorEmployee, userAccess } = employeeList;
  const [showPopup, setShowPopup] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(false);
  const [showErrMsg, setShowErrMsg] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    if ((userAccess?.length > 0
      && userAccess.filter((i) => i.pageCode === 'userAccess' && i.writeAccess)?.length > 0)) {
      setHasAccess(true);
    }
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchInput(e.target.value);
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const handlePopupChange = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (searchInput !== '') {
      const apiUrl = `/api/employee/GetEmployeeList/${searchInput}`;
      dispatch(getEmployee(apiUrl));
    }
  }, [searchInput]);

  useEffect(() => {
    if (errorEmployee !== '') {
      setErrorMessage(errorEmployee);
      setShowErrMsg(true);
    }
  }, [errorEmployee]);

  return (
    <>
      <p className="h5">Search user to provide access</p>
      <div className="form-control-icon-end mb-3">
        <input type="text" className="form-control" name="userSearch" value={searchInput} placeholder="Search" onBlur={handleChange} onKeyDownCapture={handleKeyPress} onChange={(e) => setSearchInput(e.value)} />
        <FontAwesomeIcon icon={faTimes} className="me-1 input-icon" onClick={() => { setSearchInput(''); }} />
      </div>
      <p className="h5">Search Result</p>
      {showErrMsg
        ? (
          <Alert
            message={errorMessage}
            alertClass="light-danger"
            onClose={() => setShowErrMsg(false)}
          />
        )
        : (searchInput !== '' ? employees?.map((item) => (
          <div
            className="card user-access-card mb-2"
            data-bs-toggle="modal"
            data-bs-target="#userAccessModal"
          >
            <div className="card-body">
              <div className="row" onClick={() => { setShowPopup(true); setSelectedEmployee(item); }}>
                <div className="col">
                  <p className="text-muted mb-0">User</p>
                  <p className="mb-0">
                    <strong>
                      {item.firstName}
                      {' '}
                      {item.lastName}
                    </strong>
                  </p>
                </div>
                <div className="col">
                  <p className="text-muted mb-0">Owner</p>
                  <p className="mb-0"><strong>{item.supervisorName}</strong></p>
                </div>
                <div className="col">
                  <p className="text-muted mb-0">Email</p>
                  <p className="mb-0"><strong>{item.email}</strong></p>
                </div>
              </div>
            </div>
          </div>
        )) : '')}
      {showPopup && <div className="modal-backdrop fade show" />}
      <div>
        {showPopup ? (
          <PermissionPopup
            isOpen={showPopup}
            employee={selectedEmployee}
            onChange={handlePopupChange}
            hasAccess={hasAccess}
          />
        )
          : ''}
      </div>
    </>
  );
};

export default Search;
