import React, { useState } from 'react';

const Tabs = (props) => {
  const [navigators] = useState(props.tabList);
  const [selectedTab, setSelectedTab] = useState(props.selected);

  function handleClick(value) {
    setSelectedTab(value);
    props.onClick(value);
  }
  return (
    <div className="d-flex justify-content-between mb-3">
      <ul className={`nav feedback-nav ${props.size === 'sm' ? 'nav-pills-sm' : ''}`} id="pills-tab" role="tablist">
        {navigators.map((navigator) => (
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link  ${
                selectedTab === navigator.value ? 'active' : ''
              }`}
              data-bs-toggle="pill"
              data-bs-target="#pills-new"
              type="button"
              role="tab"
              aria-controls="pills-new"
              aria-selected="true"
              onClick={() => handleClick(navigator.value)}
            >
              {navigator.label}
            </button>
          </li>
        ))}
      </ul>

    </div>

  );
};
export default Tabs;
