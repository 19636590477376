import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Context as MenuContext } from '../Context/MenuContext';
import FeedbackScoreCard from '../Components/Feedback/FeedbackScoreCard';
import BarChart from '../Components/Common/Charts/BarChart';
import PieChart from '../Components/Common/Charts/PieChart';
import Loader from '../Components/Loader';

const ArcusVisitorPage = () => {
  const [visitorMonthlyData, setVisitorMonthlyData] = useState([]);
  const [visitorYearlyData, setVisitorYearlyData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [monthlyCategories, setMonthlyCategories] = useState([]);
  const [monthlySeries, setMonthlySeries] = useState([]);
  const [yearlyCategories, setYearlyCategories] = useState([]);
  const [yearlySeries, setYearlySeries] = useState([]);
  const [pieChartCategories, setPieChartCategories] = useState([]);
  const [pieChartSeries, setPieChartSeries] = useState([]);
  const [, , , setSelectedMenu, , setSelectedSubMenu] = useContext(MenuContext);

  useEffect(() => {
    setSelectedSubMenu('arcusVisitors');
    setSelectedMenu('userManagement');
  }, []);

  function calculatePercentageChange(current, previous) {
    if (previous === 0) {
      return current === 0 ? 0 : 100;
    }
    return (((current - previous) / Math.abs(previous)) * 100).toFixed(2);
  }

  function chartMonthlyDataAssign(data) {
    const names = [...new Set(data.monthlyVisitors.map((e) => e.time))];
    const categories = [...new Set(data.monthlyVisitors.filter((p) => p.time === names[1])
      .sort((a, b) => b.count - a.count).slice(0, 5)
      .map((e) => e.pageName))];

    const colOneData = [];
    const colTwoData = [];
    for (let index = 0; index < categories.length; index++) {
      colOneData.push(data.monthlyVisitors.filter((p) => p.time === names[1] && categories[index] === p.pageName).map((p) => p.count)[0]);
      colTwoData.push(data.monthlyVisitors.filter((p) => p.time === names[0] && categories[index] === p.pageName).map((p) => p.count)[0]);
    }
    const series = [
      {
        name: names[1],
        data: colOneData,
      },
      {
        name: names[0],
        data: colTwoData,
      },
    ];
    setVisitorMonthlyData(data);
    setMonthlyCategories(categories);
    setMonthlySeries(series);
  }

  function chartYearlyDataAssign(data) {
    const names = [...new Set(data.yearlyVisitors.map((e) => e.time))];
    const categories = [...new Set(data.yearlyVisitors.filter((p) => p.time === names[1])
      .sort((a, b) => a.count - b.count).reverse().slice(0, 5)
      .map((e) => e.pageName))];

    const colOneData = [];
    const colTwoData = [];
    for (let index = 0; index < categories.length; index++) {
      colOneData.push(data.yearlyVisitors.filter((p) => p.time === names[1] && categories[index] === p.pageName).map((p) => p.count)[0]);
      colTwoData.push(data.yearlyVisitors.filter((p) => p.time === names[0] && categories[index] === p.pageName).map((p) => p.count)[0]);
    }
    const series = [
      {
        name: names[1],
        data: colOneData,
      },
      {
        name: names[0],
        data: colTwoData,
      },
    ];
    setVisitorYearlyData(data);
    setYearlyCategories(categories);
    setYearlySeries(series);
  }

  function getThirtyDaysVisitors(data) {
    const categories = data.thirtyDaysVisitorsCount.sort((a, b) => b.count - a.count).map((e) => e.pageName);
    const series = data.thirtyDaysVisitorsCount.sort((a, b) => b.count - a.count).map((e) => e.count);
    setPieChartCategories(categories);
    setPieChartSeries(series);
  }

  function getVisitorsData() {
    setLoader(true);
    axios
      .get('/api/VisitorHistory/getVisitors')
      .then((response) => {
        if (response.data) {
          chartMonthlyDataAssign(response.data);
          chartYearlyDataAssign(response.data);
          getThirtyDaysVisitors(response.data);

          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          console.log(e.message);
        }
      });
  }
  useEffect(() => {
    getVisitorsData();
  }, []);
  return (
    <>
      {
        loader ? <Loader /> : ''
      }
      <div className="wrapper">
        <div className="main">
          <div className="row">
            <div className="col-12">
              <div className="page-content">
                <div className="dashboard-card">
                  <FeedbackScoreCard title="Active Visitors" count={visitorYearlyData.activeVisitors} percent={calculatePercentageChange(visitorYearlyData.activeVisitors, visitorYearlyData.prevActiveVisitors)} duration="1 day" type={`${calculatePercentageChange(visitorYearlyData.activeVisitors, visitorYearlyData.prevActiveVisitors) > 0 ? 'success' : 'danger'}`} />
                  <FeedbackScoreCard title="Total Visitors" count={visitorMonthlyData.totalVisitor} percent={calculatePercentageChange(visitorYearlyData.totalVisitor, visitorYearlyData.prevTotalVisitor)} duration="This month" type={`${calculatePercentageChange(visitorYearlyData.totalVisitor, visitorYearlyData.prevTotalVisitor) > 0 ? 'success' : 'danger'}`} />
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <div className="card shadow-sm h-100">
                <div className="card-body">
                  <BarChart categories={yearlyCategories} series={yearlySeries} title="Year wise page visit count" />
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <div className="card shadow-sm h-100">
                <div className="card-body">
                  <BarChart categories={monthlyCategories} series={monthlySeries} title="Month wise page visit count" />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card shadow-sm h-100">
                <div className="card-body">
                  <PieChart categories={pieChartCategories} series={pieChartSeries} title="Last 30 days visit count" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ArcusVisitorPage;
