import { React, useState } from 'react';
import './style.css';

const Tooltip = ({ text, tooltip }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className="d-inline-block position-relative ms-auto"
      onMouseEnter={() => { setShowTooltip(true); }}
      onMouseLeave={() => { setShowTooltip(false); }}
    >
      {text}
      {showTooltip && (
        <div
          className="custom-tooltip shadow-sm border-2 rounded-2 show"
          role="tooltip"
        >
          <div className="arrow" />
          <div className="tooltip-inner">{tooltip}</div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
