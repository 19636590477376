import React, { useState } from 'react';

export const Context = React.createContext();

export const FileUploadContext = ({ children }) => {
  const [reloadFileHistory, setReloadFileHistory] = useState('');

  return (
    <Context.Provider value={[reloadFileHistory, setReloadFileHistory]}>
      {children}
    </Context.Provider>
  );
};
