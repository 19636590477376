import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';

import SideNavigator from './Components/SideNavigator';
import Header from './Components/Header';
import Home from './Screens/Home';
import SignIn from './Components/SignIn';
import Loader from './Components/Loader';

import CostReportingUpload from './Screens/CostReportingUpload';
import AWSAzureImageUpload from './Screens/AWSAzureImageUpload';
import ProductRegionReport from './Screens/ProductRegionReport';
import Feedback from './Screens/Feedback';
import UserAccess from './Screens/UserAccess';
import Modules from './Screens/Modules';
import EmployeeTraining from './Screens/EmployeeTraining';
import NewAnnouncement from './Screens/NewAnnouncement';
import AccessDenied from './Components/Common/AccessDenied';
import { FeedbackContext } from './Context/FeedbackContext';
import { MenuContext } from './Context/MenuContext';
import { FileUploadContext } from './Context/FileUploadContext';
import ArcusVisitorPage from './Screens/ArcusVisitorPage';
import ModuleAccess from './Screens/ModuleAccess';
import ServiceTrendingTool from './Screens/ServiceTrendingTool';
import AWSSkillBuilderMetrics from './Screens/AWSSkilBuilderMetrics';
import { getUserAccess } from './Store/actions/userAccessAction';

import { UserAccessContext } from './Context/UserAccessContext';

const App = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const apiUrl = '/api/module/GetUserAccessModule';
    dispatch(getUserAccess(apiUrl));
  }, [user]);

  function whoAmI() {
    axios
      .get('/api/auth/whoami')
      .then((response) => {
        const user = response.data;
        if (user.isAuthenticated === true) {
          setUser(response.data);
        } else {
          setUser(null);
        }
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          console.log(e.message);
        }
      })
      .finally(() => {
        setLoaded(true);
      });
  }
  useEffect(() => {
    whoAmI();
  }, []);

  return (
    <>
      {' '}
      {loaded
        ? (
          <>
            <div className="main-container">
              <UserAccessContext>
                <MenuContext>
                  <Header user={user} />
                  <div className="wrapper">
                    {user && (<SideNavigator user={user} />)}
                    <FeedbackContext>
                      <FileUploadContext>
                        <Router>
                          {user && (<Home path="/" user={user} />)}
                          {user && (<CostReportingUpload path="/costReportingUpload" />)}
                          {user && (<AWSAzureImageUpload path="/AWSAzureImageUpload" />)}
                          {user && (<ProductRegionReport path="/ProductRegionReport" />)}
                          {user && (<UserAccess path="/userAccess" user={user} />)}
                          {user && (<ModuleAccess path="/moduleAccess" user={user} />)}
                          {user && (<Modules path="/modules" user={user} />)}
                          {user && (<NewAnnouncement path="/newAnnouncement" user={user} />)}
                          {user && (<Feedback path="/feedback" />)}
                          {user && (<EmployeeTraining path="/employeeTraining" />)}
                          {user && (<AWSSkillBuilderMetrics path="/awsskillbuildermetrics" />)}
                          {user && (<ArcusVisitorPage path="/arcusVisitors" />)}
                          {user && (<ServiceTrendingTool path="/servicetrendingtool" />)}
                          <AccessDenied path="/accessDenied" />
                          <SignIn default />
                        </Router>
                      </FileUploadContext>
                    </FeedbackContext>
                  </div>
                </MenuContext>
              </UserAccessContext>
            </div>
          </>
        )
        : <Loader />}
      <Toaster
        position="bottom-right"
        toastOptions={{
          style: {
            padding: '1rem',
            fontSize: '1rem',
          },
          success: {
            style: {
              background: '#d1e7dd',
              color: '#212529',
            },
          },
          error: {
            style: {
              background: '#f8d7da',
              color: '#212529',
            },
          },
        }}
      />
    </>
  );
};
export default App;
