// FeedbackModuleTypes
export const GET_FEEDBACK = 'GET_FEEDBACK';
export const ERROR_GET_FEEDBACK = 'ERROR_GET_FEEDBACK';
export const GET_FEEDBACK_STATUS_RATIO = 'GET_FEEDBACK_STATUS_RATIO';
export const ERROR_GET_FEEDBACK_STATUS_RATIO = 'ERROR_GET_FEEDBACK_STATUS_RATIO';

// userAccessModuleTypes
export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const ERROR_GET_EMPLOYEE = 'ERROR_GET_EMPLOYEE';

// ModulesListTypes
export const RESET = 'RESET';
export const GET_MODULES = 'GET_MODULES';
export const ERROR_GET_MODULES = 'ERROR_GET_MODULES';
export const GET_USER_MODULES_ACCESS = 'GET_USER_MODULES_ACCESS';
export const ERROR_GET_USER_MODULES_ACCESS = 'ERROR_GET_USER_MODULES_ACCESS';
export const GET_USER_ACCESS = 'GET_USER_ACCESS';
export const ERROR_GET_USER_ACCESS = 'ERROR_GET_USER_ACCESS';
export const SAVE_USER_ACCESS_MODULES = 'SAVE_USER_ACCESS_MODULES';
export const ERROR_SAVE_USER_ACCESS_MODULES = 'ERROR_SAVE_USER_ACCESS_MODULES';

// Employee Module

export const UPDATE_EMPLOYEE_TRAINING = 'UPDATE_EMPLOYEE_TRAINING';
export const ERROR_UPDATE_EMPLOYEE_TRAINING = 'ERROR_UPDATE_EMPLOYEE_TRAINING';
export const REMOVE_EMPLOYEE_TRAINING = 'REMOVE_EMPLOYEE_TRAINING';
export const ERROR_REMOVE_EMPLOYEE_TRAINING = 'ERROR_REMOVE_EMPLOYEE_TRAINING';

// Cost Report module

export const COST_REPORT_UPLOAD_MESSAGE = 'COST_REPORT_UPLOAD_MESSAGE';
export const ERROR_COST_REPORT_UPLOAD_MESSAGE = 'ERROR_COST_REPORT_UPLOAD_MESSAGE';
export const UPLOADED_FILES = 'UPLOADED_FILES';
export const ERROR_UPLOADED_FILES = 'ERROR_UPLOADED_FILES';

// Module Delete

export const DELETE_MODULE = 'DELETE_MODULE';
export const ERROR_DELETE_MODULE = 'ERROR_DELETE_MODULE';

// New Announcement

export const ANNOUNCEMENT = 'ANNOUNCEMENT';
export const ANNOUNCEMENT_ERROR = 'ANNOUNCEMENT_ERROR';
export const ANNOUNCEMENT_LIST = 'ANNOUNCEMENT_LIST';
export const ANNOUNCEMENT_LIST_ERROR = 'ANNOUNCEMENT_LIST_ERROR';
export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT';
export const DELETE_ANNOUNCEMENT_ERROR = 'DELETE_ANNOUNCEMENT_ERROR';

// Service Cost Analytics

export const GET_GRAPHDATA = 'GET_GRAPHDATA';
export const ERROR_GET_GRAPHDATA = 'ERROR_GET_GRAPHDATA';
export const GET_TRENDING_SERVICE_DATA = 'GET_TRENDING_SERVICE_DATA';
export const ERROR_GET_TRENDING_SERVICE_DATA = 'ERROR_GET_TRENDING_SERVICE_DATA';
export const GET_TRENDING_SERVICE_CUMULATIVE_DATA = 'GET_TRENDING_SERVICE_CUMULATIVE_DATA';
export const ERROR_GET_TRENDING_SERVICE_CUMULATIVE_DATA = 'ERROR_GET_TRENDING_SERVICE_CUMULATIVE_DATA';
export const GET_FORECASTING_SERVICE_DATA = 'GET_FORECASTING_SERVICE_DATA';
export const ERROR_GET_FORECASTING_SERVICE_DATA = 'ERROR_GET_FORECASTING_SERVICE_DATA';

// AWS Skill Builder Metrics Dashboard

export const GET_AWS_SKILL_BUILDER_METRICS_USER_LIST = 'GET_AWS_SKILL_BUILDER_METRICS_USER_LIST';
export const GET_AWS_SKILL_BUILDER_METRICS_USER_LIST_ERROR = 'GET_AWS_SKILL_BUILDER_METRICS_USER_LIST_ERROR';
