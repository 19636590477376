import axios from 'axios';
import {
  GET_GRAPHDATA,
  ERROR_GET_GRAPHDATA,
  GET_TRENDING_SERVICE_DATA,
  ERROR_GET_TRENDING_SERVICE_DATA,
  GET_FORECASTING_SERVICE_DATA,
  ERROR_GET_FORECASTING_SERVICE_DATA,
  GET_TRENDING_SERVICE_CUMULATIVE_DATA,
  ERROR_GET_TRENDING_SERVICE_CUMULATIVE_DATA,
} from '../types';

export const getGraphData = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_GRAPHDATA,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_GET_GRAPHDATA,
      payload: error,
    });
  }
};

export const getTrendingServiceData = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);

    dispatch({
      type: GET_TRENDING_SERVICE_DATA,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_GET_TRENDING_SERVICE_DATA,
      payload: error,
    });
  }
};

export const getTrendingServiceCumulativeData = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);

    dispatch({
      type: GET_TRENDING_SERVICE_CUMULATIVE_DATA,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_GET_TRENDING_SERVICE_CUMULATIVE_DATA,
      payload: error,
    });
  }
};

export const getForecastingData = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_FORECASTING_SERVICE_DATA,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_GET_FORECASTING_SERVICE_DATA,
      payload: error,
    });
  }
};
