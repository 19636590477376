import React, {
  useState, useRef, useEffect, useContext,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons';
import csgLogo from '../../Images/csg-logo.png';
import LogOutModel from '../LogOutModel';
import { Context } from '../../Context/MenuContext';

const Header = ({ user }) => {
  const [showUserDrpDwn, setShowUserDrpDown] = useState(false);
  const [showLogOutModel, setShowLogOtModel] = useState(false);
  const logOutRef = useRef(null);
  const menuRef = useRef(null);
  const [toggleSideNav, setToggleSideNav] = useContext(Context);
  function handleClickOutside(event) {
    if (logOutRef.current && !logOutRef.current.contains(event.target)) {
      setShowUserDrpDown(false);
    }
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setToggleSideNav(false);
    }
  }
  // CB function from LogOutModel. Closes LogOutModel when confirm log out
  const handleLogOutChange = (newValue) => {
    setShowLogOtModel(newValue);
  };
  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div id="wrapper">
      <nav className="navbar navbar-expand navbar-dark bg-primary" ref={menuRef}>
        <div className="container-fluid">
          <div className="navbar-brand">
            <a href="/" className="d-none d-md-inline">
              <img
                src={csgLogo}
                alt="csgLogo"
                className="navbar-logo"
                width="100"
                style={{ maxHeight: '22px', width: 'auto', marginRight: '1rem' }}
              />
            </a>
            <span type="button" onClick={() => setToggleSideNav(!toggleSideNav)} className="d-inline d-sm-none ">
              <FontAwesomeIcon icon={faBars} className="me-2" />
            </span>
            {' '}
            {' '}

            Cloud Configuration Tool

          </div>

          {user && (
            <ul className="navbar-nav me-0">
              <li
                className="nav-item dropdown"
                onClick={() => setShowUserDrpDown(!showUserDrpDwn)}
                ref={logOutRef}
              >
                <div
                  className="nav-link dropdown-toggle p-0"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="d-flex align-items-center text-white">
                    <i className="fa-solid fa-circle-user fs-3 me-2" />
                    <span className="lh-sm">

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                        color="white"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                            fill="white"
                            fillRule="nonzero"
                            opacity="0.3"
                          />
                          <path
                            d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                            fill="white"
                            fillRule="nonzero"
                          />
                        </g>
                      </svg>

                      <span className="d-none d-lg-inline text-white ms-1">
                        {user.firstName}
                        {' '}
                        {user.lastName}
                      </span>
                    </span>
                  </div>
                </div>
                <ul className={`dropdown-menu fade-in-up ${showUserDrpDwn ? 'show' : ''}`} style={{ right: 0 }}>
                  <li>
                    <div
                      className="dropdown-item"
                      data-toggle="modal"
                      data-target="#logoutModal"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowLogOtModel(true)}
                      ref={logOutRef}
                    >
                      <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                      Logout
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          )}
        </div>
      </nav>
      {showLogOutModel && <div className="log-out-layer" />}
      <LogOutModel isOpen={showLogOutModel} onChange={handleLogOutChange} />
    </div>

  );
};
export default Header;
