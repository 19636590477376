import {
  UPDATE_EMPLOYEE_TRAINING,
  ERROR_UPDATE_EMPLOYEE_TRAINING,
  REMOVE_EMPLOYEE_TRAINING,
  ERROR_REMOVE_EMPLOYEE_TRAINING,
} from '../types';

const initialState = {
  updateEmployeeTrainingResult: '',
  updateEmployeeTrainingResultLoading: true,
  updateEmployeeTrainingResultError: '',
  removeEmployeeTrainingResult: '',
  removeEmployeeTrainingResultLoading: true,
  removeEmployeeTrainingResultError: '',
};
  /* eslint-disable func-names */
export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_EMPLOYEE_TRAINING:
      return {
        ...state,
        updateEmployeeTrainingResult: action.payload,
        updateEmployeeTrainingResultLoading: false,
      };
    case ERROR_UPDATE_EMPLOYEE_TRAINING:
      return {
        ...state,
        updateEmployeeTrainingResultError: action.payload.message,
        updateEmployeeTrainingResultLoading: false,
      };
    case REMOVE_EMPLOYEE_TRAINING:
      return {
        ...state,
        removeEmployeeTrainingResult: action.payload,
        removeEmployeeTrainingResultLoading: false,
      };
    case ERROR_REMOVE_EMPLOYEE_TRAINING:
      return {
        ...state,
        removeEmployeeTrainingResultError: action.payload.message,
        removeEmployeeTrainingResultLoading: false,
      };
    default: return state;
  }
}
