import {
  GET_FEEDBACK,
  ERROR_GET_FEEDBACK,
  GET_FEEDBACK_STATUS_RATIO,
  ERROR_GET_FEEDBACK_STATUS_RATIO,
} from '../types';

const initialState = {
  feedbacks: [],
  feedbackLoading: true,
  errorFeedback: '',
  feedbackStatusRatio: [],
  feedbackStatusRatioLoading: true,
  errorFeedbackStatusRatio: '',
};

/* eslint-disable func-names */
export default function (state = initialState, action) {
  /* eslint-enable */
  switch (action.type) {
    case GET_FEEDBACK:
      return {
        ...state,
        feedbacks: action.payload,
        feedbackLoading: false,
      };
    case ERROR_GET_FEEDBACK:
      return {
        ...state,
        feedbackLoading: false,
        errorFeedback: action.payload.message,
      };
    case GET_FEEDBACK_STATUS_RATIO:
      return {
        ...state,
        feedbackStatusRatio: action.payload,
        feedbackStatusRatioLoading: false,
      };
    case ERROR_GET_FEEDBACK_STATUS_RATIO:
      return {
        ...state,
        feedbackStatusRatioLoading: false,
        errorFeedbackStatusRatio: action.payload.message,
      };
    default: return state;
  }
}
