import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const AccessDenied = () => (
  <div className="alert alert-danger" role="alert" name="accessDenied">
    <FontAwesomeIcon icon={faExclamationTriangle} className="fa-2x text-danger me-2" />
    You are not allowed access to this section.  Please contact to the Team.
  </div>
);

export default AccessDenied;
