import React, { useState } from 'react';

export const Context = React.createContext();

export const UserAccessContext = ({ children }) => {
  const [userAccessDetails, setUserAccessDetails] = useState('');

  return (
    <Context.Provider value={[userAccessDetails, setUserAccessDetails]}>
      {children}
    </Context.Provider>
  );
};
