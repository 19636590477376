import React from 'react';

const PageHeader = (props) => {
  const { pageTitle } = props;
  return (
    <div className="page-header">
      <div className="page-header-left">
        <div className="page-title">
          {pageTitle}
        </div>
        <nav aria-label="breadcrumb" className="small">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/">Cloud Configuration Tool</a></li>
            <li className="breadcrumb-item active" aria-current="page">{pageTitle}</li>
          </ol>
        </nav>
      </div>
      <div className="page-header-right">
        <div className="page-header-right">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
