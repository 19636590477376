import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { Context } from '../Context/MenuContext';
import { Context as FileUploadContext } from '../Context/FileUploadContext';
import PageHeader from '../Components/Common/PageHeader';
import FileSubmitPopup from '../Components/CostReport/FileSubmitPopup';
import CostUploadedList from '../Components/CostReport/CostUploadedList';

const CostReportingUpload = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [, , , setSelectedMenu, , setSelectedSubMenu] = useContext(Context);
  const [, setReloadFileHistory] = useContext(FileUploadContext);
  const [hasAccess, setHasAccess] = useState(false);

  const employeeList = useSelector((state) => state.employeeList);
  const {
    userAccess,
  } = employeeList;

  useEffect(() => {
    if (userAccess?.length > 0
      && userAccess.filter((i) => i.pageCode === 'costReportingUpload' && i.writeAccess)?.length > 0) {
      setHasAccess(true);
    }
  }, []);

  useEffect(() => {
    setSelectedMenu('service');
    setSelectedSubMenu('costReportingUpload');
  }, []);

  const handlePopupChange = () => {
    setShowPopup(false);
  };

  const handlePopupSuccessChange = () => {
    setShowPopup(false);
    setReloadFileHistory('reload');
  };

  return (
    <>
      <div className="wrapper">
        <div className="main">
          <div className="row">
            <div className="col-lg-12">
              <PageHeader pageTitle="Ascendon Cost Reporting">
                {hasAccess
                  ? (
                    <button
                      className="btn btn-primary"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#uploadFileModal"
                      onClick={() => setShowPopup(true)}
                    >
                      <FontAwesomeIcon icon={faUpload} />
                      {' '}
                      Upload
                    </button>
                  )
                  : ''}
              </PageHeader>
              <CostUploadedList type="costreport" />
            </div>
          </div>
        </div>
      </div>
      {showPopup
        && (
          <FileSubmitPopup onSubmit={handlePopupSuccessChange} onClose={handlePopupChange} screen="costreport" />
        )}
      {showPopup && <div className="modal-backdrop fade show" />}

    </>
  );
};
export default CostReportingUpload;
