import axios from 'axios';
import {
  COST_REPORT_UPLOAD_MESSAGE,
  ERROR_COST_REPORT_UPLOAD_MESSAGE,
  UPLOADED_FILES,
  ERROR_UPLOADED_FILES,
} from '../types';

export const uploadFile = (apiUrl, payload) => async (dispatch) => {
  try {
    const res = await axios.post(apiUrl, payload);
    dispatch({
      type: COST_REPORT_UPLOAD_MESSAGE,
      payload: await (res.data),
    });
  } catch (error) {
    dispatch({
      type: ERROR_COST_REPORT_UPLOAD_MESSAGE,
      payload: error,
    });
  }
};

export const getUploadedFileHistory = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: UPLOADED_FILES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_UPLOADED_FILES,
      payload: error,
    });
  }
};
