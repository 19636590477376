import React from 'react';
import Chart from 'react-apexcharts';

const PieChart = (props) => {
  const { series, categories, title } = props;
  const options = {
    chart: {
      type: 'pie',
      width: '100%',
    },
    dataLabels: {
      enabled: false,
    },
    labels: categories,
    legend: {
      position: 'bottom',
      offsetY: 20,
    },
    title: {
      text: title,
      align: 'left',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: '#263238',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val}`;
        },
      },
    },
  };
  return (
    <div>
      <Chart options={options} series={series} type="pie" height="300" />
    </div>
  );
};
export default PieChart;
