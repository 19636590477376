import { combineReducers } from 'redux';
import feedbackReducer from './feedbackReducer';
import userAccessReducer from './userAccessReducer';
import modulesReducer from './modulesReducer';
import employeeReducer from './employeeReducer';
import CostReportReducer from './costReportReducer';
import announcementReducer from './announcementReducer';
import servicesAnalyticsReducer from './servicesAnalyticsReducer';
import skillBuilderMetricsReducer from './skillBuilderMetricsReducer';

export default combineReducers({
  feedbackList: feedbackReducer,
  employeeList: userAccessReducer,
  moduleList: modulesReducer,
  employeeTraining: employeeReducer,
  costReportResult: CostReportReducer,
  newAnnouncement: announcementReducer,
  servicesAnalytics: servicesAnalyticsReducer,
  skillBuilderMetrics: skillBuilderMetricsReducer,
});
