import React from 'react';
import CsvDownloader from 'react-csv-downloader';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CsvDownload = (props) => {
  const {
    fileName, csvData,
  } = props;

  return (
    <CsvDownloader
      className="d-inline"
      filename={fileName}
      extension=".csv"
      datas={csvData}
    >
      <button
        className="btn btn-primary"
        type="button"
      >
        <FontAwesomeIcon icon={faCloudDownloadAlt} className="input-icon" />
      </button>
    </CsvDownloader>
  );
};

export default CsvDownload;
