import React, { useContext, useEffect } from 'react';
import Search from '../Components/AdminAccess/Search';
import { Context } from '../Context/MenuContext';
import PageHeader from '../Components/Common/PageHeader';

const UserAccess = ({ user }) => {
  const [, , , setSelectedMenu, , setSelectedSubMenu] = useContext(Context);
  useEffect(() => {
    setSelectedSubMenu('userAccess');
    setSelectedMenu('admin');
  }, []);
  return (
    <div className="wrapper">
      <div className="main">
        <div className="row">
          <div className="col-lg-12">
            <PageHeader pageTitle="User Access" />
            <div className="page-content">
              <div className="card shadow">
                <div className="card-body">
                  <Search user={user} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserAccess;
