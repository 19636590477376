import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLightbulb, faBug, faEllipsisV, faSmile, faFrown, faMeh,
} from '@fortawesome/free-solid-svg-icons';
import { Context } from '../../../Context/FeedbackContext';

const Popup = (props) => {
  const {
    feedback, isOpen, onChange, tab, hasAccess,
  } = props;
  const defaultFeedback = () => ({
    id: feedback.id,
    description: feedback.description,
    category: '',
    sentiments: '',
    label: '',
    Comments: '',
    status: '',
    jiraId: '',
  });
  const [category, setCategory] = useState('');
  const [sentiments, setSentiments] = useState('');
  const [label, setLabel] = useState('');
  const [jiraId, setJiraId] = useState('');
  const [comments, setComments] = useState('');
  const [validation, setValidation] = useState(defaultFeedback());
  const [feedbackResults, setFeedbackResults] = useState(defaultFeedback());
  const [successMessage, setSuccessMessage] = useState('');
  const [feedbackContextState, setFeedbackContextState] = useContext(Context);
  const modalStyle = {
    display: `${isOpen ? 'block' : 'none'}`,
    paddingRight: '4px',
  };
  const handleCommentChange = (e) => {
    const { target } = e;
    setComments(target.value);
  };
  const handleValidation = (e) => {
    const { target } = e;
    const {
      name,
    } = target;
    setValidation({
      ...validation,
      [name]: '',
    });
    if (name === 'label') {
      setLabel(target.value);
    }
    if (name === 'jiraId') {
      setJiraId(target.value);
    }
  };
  const submitForm = (value) => {
    if (value === 'archive') {
      setFeedbackResults(
        {
          ...feedbackResults,
          id: feedback.id,
          category: category === '' ? feedback.category : category,
          sentiments: sentiments === '' ? feedback.sentiments : sentiments,
          label: label === '' ? feedback.label : label,
          comments: comments === '' ? feedback.comments : comments,
          status: 'Archive',

        },
      );
    } else if (tab === 'open') {
      setValidation({
        ...validation,
        category: category === '' ? 'border-danger' : '',
        sentiments: sentiments === '' ? 'border-danger' : '',
        label: label === '' ? 'border-danger' : '',
      });
      setFeedbackResults(
        {
          ...feedbackResults,
          id: feedback.id,
          category,
          sentiments,
          label,
          comments,
          status: 'InProgress',

        },
      );
    } else if (tab === 'inprogress') {
      setValidation({
        ...validation,
        jiraId: jiraId === '' ? 'border-danger' : '',
      });
      setFeedbackResults(
        {
          ...feedbackResults,
          id: feedback.id,
          category: feedback.category,
          sentiments: feedback.sentiments,
          label: feedback.label,
          comments: comments === '' ? feedback.comments : comments,
          status: 'Closed',
          jiraId,
        },
      );
    }
  };

  const submitFeedback = () => {
    let validErrorExist = false;

    Object.keys(validation).forEach((o) => {
      if (validation[o]) {
        validErrorExist = true;
      }
    });
    if (!validErrorExist || feedbackResults.status === 'Archive') {
      axios.post('/api/feedback/UpdateFeedback', feedbackResults)
        .then((response) => {
          if (response) {
            setSuccessMessage(response.data);
            if (tab === 'open') {
              setFeedbackContextState({
                ...feedbackContextState,
                reload: true,
              });
            } else if (tab === 'inprogress') {
              setFeedbackContextState({
                ...feedbackContextState,
                reloadOpenTab: true,
              });
            }
            setCategory('');
            setSentiments('');
            setLabel('');
            setComments('');
            setJiraId('');
          }
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            console.log('error');
          }
        });
    }
  };
  useEffect(() => {
    if (feedbackResults.category !== '' || feedbackResults.status === 'Archive') { submitFeedback(); }
  }, [feedbackResults]);
  return (
    <>
      <div
        className={`modal fade ${isOpen ? 'show' : ''}`}
        id="newFeedbackModal"
        tabIndex="-1"
        aria-labelledby="newFeedbackModalLabel"
        aria-hidden="true"
        style={modalStyle}

      >
        {' '}

        <div className="modal-dialog">
          <div className="modal-content">
            { successMessage === 'success'
              ? (
                <>
                  <div className="modal-header">
                    <h5 className="modal-title" id="newFeedbackModalLabel">Feedback Successfully Updated</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { onChange(); setSuccessMessage(''); }} />
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-outline-primary" onClick={() => { onChange(); setSuccessMessage(''); }}>OK</button>
                  </div>
                </>
              )
              : (
                <>
                  <div className="modal-header">
                    <h5 className="modal-title" id="newFeedbackModalLabel">Feedback</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onChange} />
                  </div>
                  <div className="modal-body">
                    <p>
                      {feedback.description}
                    </p>
                    <div id="category" className={`bg-light border p-2 mb-3 ${validation?.category}${tab !== 'open' && tab !== 'inprogress' ? 'disabled' : ''}`}>
                      <h6>Select Feedback Category</h6>
                      <label className={`btn btn-outline-success me-1 ${(category === 'idea' || feedback.category === 'idea') ? 'active' : ''}`} htmlFor="idea">
                        <FontAwesomeIcon icon={faLightbulb} className="me-1" />
                        <span>
                          Idea
                        </span>
                        <input type="radio" name="category" id="idea" hidden onChange={handleValidation} onClick={() => { setCategory('idea'); }} />
                      </label>
                      <label className={`btn btn-outline-danger me-1 ${(category === 'bug' || feedback.category === 'bug') ? 'active' : ''}`} htmlFor="bug">
                        <FontAwesomeIcon icon={faBug} className="me-1" />
                        <span>
                          Bug
                        </span>
                        <input type="radio" name="category" id="bug" hidden onChange={handleValidation} onClick={() => { setCategory('bug'); }} />
                      </label>
                      <label className={`btn btn-outline-secondary ${(category === 'other' || feedback.category === 'other') ? 'active' : ''}`} htmlFor="other">
                        <FontAwesomeIcon icon={faEllipsisV} className="me-1" />
                        <span>
                          Other
                        </span>
                        <input type="radio" name="category" id="other" hidden onChange={handleValidation} onClick={() => { setCategory('other'); }} />
                      </label>
                    </div>

                    <div id="sentiments" className={`border bg-light p-2 mb-3 ${validation?.sentiments}`}>
                      <h6>Select Feedback Sentiment</h6>
                      <label className={`btn btn-outline-success me-1 ${(sentiments === 'positive' || feedback.sentiments === 'positive') ? 'active' : ''}`} htmlFor="positive">
                        <FontAwesomeIcon icon={faSmile} className="me-1" />
                        <span>
                          Positive
                        </span>
                        <input type="radio" name="sentiments" id="positive" hidden onChange={handleValidation} onClick={() => { setSentiments('positive'); }} />
                      </label>
                      <label className={`btn btn-outline-danger me-1 ${(sentiments === 'negative' || feedback.sentiments === 'negative') ? 'active' : ''}`} htmlFor="negative">
                        <FontAwesomeIcon icon={faFrown} className="me-1" />
                        <span>
                          Negative
                        </span>
                        <input type="radio" name="sentiments" id="negative" hidden onChange={handleValidation} onClick={() => { setSentiments('negative'); }} />
                      </label>
                      <label className={`btn btn-outline-secondary ${(sentiments === 'neutral' ? 'active' : '' || feedback.sentiments === 'neutral') ? 'active' : ''}`} htmlFor="neutral">
                        <FontAwesomeIcon icon={faMeh} className="me-1" />
                        <span>
                          Neutral
                        </span>
                        <input type="radio" name="sentiments" id="neutral" hidden onChange={handleValidation} onClick={() => { setSentiments('neutral'); }} />
                      </label>
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Add Label</label>
                      <input type="text" className={`form-control ${validation?.label}`} name="label" placeholder={`${tab !== 'open' ? feedback.label : 'label'}`} onChange={handleValidation} />
                    </div>
                    <div className={`mb-3 ${tab === 'open' || tab === 'archive' ? 'd-none' : 'd-block'}`}>
                      <label className="form-label">Jira Task</label>
                      <input type="text" className={`form-control ${validation?.jiraId} `} name="jiraId" placeholder={`${tab === 'inprogress' ? 'Jira Task' : feedback.jiraId}`} onChange={handleValidation} />
                    </div>

                    <div>
                      <label className="form-label">Add Comment</label>
                      <textarea className="form-control" placeholder={`${tab !== 'open' ? feedback.comments : 'comments'}`} onChange={handleCommentChange} />
                    </div>
                  </div>
                  { (tab === 'inprogress' || tab === 'open')
                    ? (
                      <>
                        {hasAccess ? (
                          <div className="modal-footer ">
                            <button type="button" className="btn btn-outline-primary" onClick={() => submitForm('archive')}>Archive</button>
                            <button type="button" className="btn btn-primary" onClick={() => submitForm(tab)}>Submit</button>
                            {/* InProgress status need to change as Open or Complete, once Open status change in Arcus side while new feedback created  */}
                          </div>
                        )
                          : (
                            <div className="modal-footer ">
                              <button type="button" className="btn btn-primary" onClick={() => { onChange(); }}>Close</button>
                            </div>
                          )}
                      </>
                    )
                    : (
                      <>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-primary" onClick={() => { onChange(); }}>Close</button>
                          {/* InProgress status need to change as Open or Complete, once Open status change in Arcus side while new feedback created  */}
                        </div>
                      </>
                    )}
                </>
              )}
          </div>
        </div>

      </div>
    </>
  );
};
export default Popup;
