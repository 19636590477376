import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import Select from '../../Common/Select';
import Button from '../../Common/Button';

const ModulePopup = (props) => {
  const {
    onClose,
    type,
    row,
    hasAccess,
  } = props;

  const [module, setModule] = useState('');
  const [page, setPage] = useState('');
  const [application, setApplication] = useState('');

  const defaultModule = () => ({
    Id: '',
    ModuleName: '',
    PageCode: '',
    PageName: '',
    Application: '',
  });
  const [moduleResults, setModuleResults] = useState(defaultModule());

  useEffect(() => {
    if (type === 'Edit') {
      setModule(row[1]);
      setPage(row[2]);
      setApplication(row[4]);
      setModuleResults(
        {
          Id: row[0],
          ModuleName: row[1],
          PageName: row[2],
          PageCode: row[3],
          Application: row[4],
        },
      );
    }
  }, []);

  const applicationChange = (e) => {
    const { target } = e;
    const { value } = target;
    setApplication(value);
    setModuleResults(
      {
        ...moduleResults,
        Application: value,
        Id: row[0],
      },
    );
  };

  const moduleChange = (e) => {
    const { target } = e;
    const { value } = target;
    setModule(value);
    setModuleResults(
      {
        ...moduleResults,
        ModuleName: value,
        Id: row[0],
      },
    );
  };

  const pageChange = (e) => {
    const { target } = e;
    const { value } = target;
    setPage(value);
    setModuleResults(
      {
        ...moduleResults,
        PageCode: (value.charAt(0).toLowerCase() + value.slice(1)).replace(/\s/g, ''),
        PageName: value,
        Id: row[0],
      },
    );
  };
  const apiCall = () => {
    axios.post('/api/module/SaveAndUpdateModule', moduleResults)
      .then((response) => {
        if (response) {
          onClose();
          toast.success('Module successfully added!');
        }
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.log(err);
          toast.error(err);
        }
      });
  };
  return (
    <>
      <div
        className="modal fade show"
        id="uploadFileModal"
        tabIndex="-1"
        aria-labelledby="uploadFileModalLabel"
        aria-hidden="true"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="uploadFileModalLabel">
                {type}
                {' '}
                Module
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose} />
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label className="form-label">Module Name</label>
                  <input type="text" name="moduleName" className="form-control" placeholder="Module Name ex: File Upload" onChange={moduleChange} value={module} />
                </div>
                <div className="mb-3">
                  <label className="form-label">Page Name</label>
                  <input type="text" className="form-control" name="pageName" placeholder="Page Name ex: Image Upload" onChange={pageChange} value={page} />
                </div>
                <div className="mb-3">
                  <Select
                    name="ApplicationSelect"
                    title="Choose Application Name"
                    value={application}
                    placeholder="Pick One..."
                    options={{ Arcus: 'Arcus', ConfigTool: 'ConfigTool' }}
                    onChange={applicationChange}
                    required
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              {hasAccess
                ? <Button className={`btn btn-primary ${module === '' || page === '' || application === '' ? 'disabled' : ''}`} onClick={apiCall} value="Save changes" pageCode="modules" />
                : ''}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};
export default ModulePopup;
