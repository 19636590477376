import React from 'react';
import Chart from 'react-apexcharts';

const BarChart = (props) => {
  const { series, categories, title } = props;
  const options = {
    chart: {
      type: 'bar',
      width: '100%',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: title,
      align: 'left',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: '#263238',
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['transparent'],
    },
    xaxis: {
      categories,
      labels: {
        formatter: (str) => {
          const n = 10;
          return str.length > n ? `${str.substr(0, n - 1)}...` : str;
        },
        rotateAlways: true,
        rotate: -25,
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      fontSize: '8px',
      horizontalAlign: 'center',
    },
    responsive: [
      {
        breakpoint: 767,
        options: {
          xaxis: {
            labels: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
        },
      },
    ],
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val}`;
        },
      },
    },
  };
  return (
    <div>
      <Chart options={options} series={series} type="bar" height="300" />
    </div>
  );
};
export default BarChart;
