import {
  RESET,
  GET_EMPLOYEE,
  ERROR_GET_EMPLOYEE,
  GET_USER_MODULES_ACCESS,
  ERROR_GET_USER_MODULES_ACCESS,
  GET_USER_ACCESS,
  ERROR_GET_USER_ACCESS,
  SAVE_USER_ACCESS_MODULES,
  ERROR_SAVE_USER_ACCESS_MODULES,
} from '../types';

const initialState = {
  employees: [],
  employeeLoading: true,
  errorEmployee: '',
  userModulesAccess: [],
  userModulesAccessLoading: true,
  errorUserModulesAccess: '',
  userAccess: [],
  userAccessLoading: true,
  errorUserAccess: '',
  saveUserAccessResult: '',
  saveUserAccessResultLoading: true,
  saveUserAccessResultError: '',
};
/* eslint-disable func-names */
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE:
      return {
        ...state,
        employees: action.payload,
        employeeLoading: false,
      };
    case ERROR_GET_EMPLOYEE:
      return {
        ...state,
        errorEmployee: action.payload.message,
        employeeLoading: false,
      };
    case GET_USER_MODULES_ACCESS:
      return {
        ...state,
        userModulesAccess: action.payload,
        userModulesAccessLoading: false,
      };
    case ERROR_GET_USER_MODULES_ACCESS:
      return {
        ...state,
        errorUserModulesAccess: action.payload.message,
        userModulesAccessLoading: false,
      };
    case GET_USER_ACCESS:
      return {
        ...state,
        userAccess: action.payload,
        userAccessLoading: false,
      };
    case ERROR_GET_USER_ACCESS:
      return {
        ...state,
        errorUserAccess: action.payload.message,
        userAccessLoading: false,
      };
    case SAVE_USER_ACCESS_MODULES:
      return {
        ...state,
        saveUserAccessResult: action.payload,
        saveUserAccessResultLoading: false,
      };
    case ERROR_SAVE_USER_ACCESS_MODULES:
      return {
        ...state,
        saveUserAccessResultError: action.payload.message,
        saveUserAccessResultLoading: false,
      };
    case RESET:
      return {
        ...state,
        saveUserAccessResult: '',
        saveUserAccessResultLoading: true,
        saveUserAccessResultError: '',
      };
    default: return state;
  }
}
