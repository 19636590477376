import { React } from 'react';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css';
import Tooltip from '../Tooltip';

const ScoreCard = (props) => {
  const {
    title, count, infoText, icon,
  } = props;

  return (
    <div className="card score-card w-25 shadow">
      <div className="card-body d-flex flex-column justify-content-between">
        <div className="d-flex align-items-center">
          {icon && (
            <span className="score-card-icon me-2">
              {icon}
            </span>
          )}
          <p className="h4 mb-0 ">{title}</p>
          {infoText && (
            <>
              <Tooltip text={<FontAwesomeIcon icon={faInfo} />} tooltip={infoText} />
            </>
          )}
        </div>
        <p className="h1 mb-0">{count}</p>
      </div>
    </div>
  );
};

export default ScoreCard;
