import React, {
  useState, useRef, useEffect, useContext,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { getFeedbackStatusRatio } from '../../Store/actions/feedbackAction';
import { Context } from '../../Context/FeedbackContext';

const DashboardFilter = () => {
  // Redux
  const dispatch = useDispatch();
  const feedbackList = useSelector((state) => state.feedbackList);
  const { feedbackStatusRatio } = feedbackList;

  const [feedbackContextState, setFeedbackContextState] = useContext(Context);
  const { selectedTime, selectecTimeVal, contextFeedbackStatusRatio } = feedbackContextState;
  const [timePeriod, setTimeperiod] = useState(false);
  const logOutRef = useRef(null);
  const Todaydate = new Date();
  function handleClickOutside(event) {
    if (logOutRef.current && !logOutRef.current.contains(event.target)) {
      setTimeperiod(false);
    }
  }
  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (feedbackStatusRatio?.length === 0 || feedbackStatusRatio === undefined) {
      const apiUrl = '/api/feedback/GetFeedback';
      dispatch(getFeedbackStatusRatio(apiUrl));
    }
  }, [dispatch]);

  useEffect(() => {
    if (contextFeedbackStatusRatio?.length === 0) {
      if (feedbackStatusRatio.length > 0) {
        const newFeedbacks = feedbackStatusRatio.filter((c) => c.status === 'Open').length;
        const actionFeedbacks = feedbackStatusRatio.filter((c) => c.status !== 'Open').length;
        setFeedbackContextState({
          ...feedbackContextState,
          selectedLengthNew: newFeedbacks,
          selectedLengthAction: actionFeedbacks,
          contextFeedbackStatusRatio: feedbackStatusRatio,
          selectedPercentNew: Math.round((newFeedbacks / feedbackStatusRatio?.length) * 100),
          selectedPercentAction: Math.round((actionFeedbacks / feedbackStatusRatio?.length) * 100),
        });
      }
    }
  }, [feedbackStatusRatio]);
  useEffect(() => {
    if (contextFeedbackStatusRatio?.length > 0) {
      const date = new Date(Todaydate.getTime() - (selectecTimeVal * 24 * 60 * 60 * 1000));
      const compareDate = new Date(Todaydate.getTime() - ((selectecTimeVal * 2) * 24 * 60 * 60 * 1000));
      const previousNewFeedbacks = contextFeedbackStatusRatio.filter((x) => new Date(x.lastUpdatedOn) >= compareDate && new Date(x.lastUpdatedOn) <= date)?.length;
      const previousActionFeedbacks = contextFeedbackStatusRatio.filter((x) => x.status !== 'Open' && new Date(x.lastUpdatedOn) >= compareDate && new Date(x.lastUpdatedOn) <= date)?.length;
      const selectedLengthNew = contextFeedbackStatusRatio.filter((x) => x.status === 'Open' && new Date(x.createdOn) >= date)?.length;
      const selectedLengthAction = contextFeedbackStatusRatio.filter((x) => x.status !== 'Open' && new Date(x.lastUpdatedOn) >= date)?.length;
      const selectedPercentNew = previousNewFeedbacks > 0 ? Math.round(((selectedLengthNew - previousNewFeedbacks) / previousNewFeedbacks) * 100) : selectedLengthNew;
      const selectedPercentAction = previousActionFeedbacks > 0 ? Math.round(((selectedLengthAction - previousActionFeedbacks) / previousActionFeedbacks) * 100) : selectedLengthAction;
      const allNewFeedbacks = contextFeedbackStatusRatio?.filter((x) => x.status === 'Open')?.length;
      const allActionFeedbacks = contextFeedbackStatusRatio?.filter((x) => x.status !== 'Open')?.length;

      switch (selectedTime) {
        case 'Since 7 days':
          setFeedbackContextState({
            ...feedbackContextState,
            selectedLengthNew,
            selectedLengthAction,
            selectedPercentNew,
            selectedPercentAction,
          });
          break;
        case 'Since 30 days':
          setFeedbackContextState({
            ...feedbackContextState,
            selectedLengthNew,
            selectedLengthAction,
            selectedPercentNew,
            selectedPercentAction,
          });
          break;
        case 'Since 90 days':
          setFeedbackContextState({
            ...feedbackContextState,
            selectedLengthNew,
            selectedLengthAction,
            selectedPercentNew,
            selectedPercentAction,
          });
          break;
        case 'All time':
          setFeedbackContextState({
            ...feedbackContextState,
            selectedLengthNew: allNewFeedbacks,
            selectedLengthAction: allActionFeedbacks,
            selectedPercentNew: Math.round((allNewFeedbacks / contextFeedbackStatusRatio?.length) * 100),
            selectedPercentAction: Math.round((allActionFeedbacks / contextFeedbackStatusRatio?.length) * 100),
          });
          break;
        default:
          setFeedbackContextState({
            ...feedbackContextState,
            selectedTime: 'Invalid time selection',
          });
      }
    }
  }, [selectedTime, selectecTimeVal]);

  const updateTimePeriod = (label, value) => {
    setFeedbackContextState({
      ...feedbackContextState,
      selectedTime: label,
      selectecTimeVal: value,
    });
  };
  return (
    <div className="dropdown">
      <div
        className="btn btn-icon dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={() => setTimeperiod(!timePeriod)}
        ref={logOutRef}
      >
        <FontAwesomeIcon icon={faEllipsisV} style={{ outline: 'none' }} />
      </div>

      <ul className={`dropdown-menu dropdown-menu-end ${timePeriod ? 'show' : ''}`} ref={logOutRef} data-bs-popper>

        <li className="dropdown-item" type="button" onClick={() => updateTimePeriod('Since 7 days', 7)}>Last 7 days</li>
        <li className="dropdown-item" type="button" onClick={() => updateTimePeriod('Since 30 days', 30)}>Last 1 month</li>
        <li className="dropdown-item" type="button " onClick={() => updateTimePeriod('Since 90 days', 90)}>Last 3 months</li>
        <li className="dropdown-item" type="button" onClick={() => updateTimePeriod('All time')}>All time</li>
      </ul>
    </div>
  );
};
export default DashboardFilter;
