import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import BugReportIcon from '@mui/icons-material/BugReport';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getFeedbacks } from '../../../Store/actions/feedbackAction';
import Popup from '../Popup';

import { Context } from '../../../Context/FeedbackContext';

const FeedbackList = (props) => {
  const { tab, hasAccess } = props;
  const dispatch = useDispatch();
  const feedbackList = useSelector((state) => state.feedbackList);
  const [feedbackContextState, setFeedbackContextState] = useContext(Context);
  const { reloadOpenTab } = feedbackContextState;
  const { feedbacks } = feedbackList;
  const [showPopup, setShowPopup] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState('');
  const [tableData, setTableData] = useState([]);
  const [date, setDate] = useState(Date);
  const [secondDate, setSecondDate] = useState(Date);
  const todayDate = new Date();

  const openPopup = (rowIndex) => {
    for (let i = 0; i < feedbacks.length; i++) {
      if (feedbacks[i].id === rowIndex) {
        setSelectedFeedback(feedbacks[i]);
        setShowPopup(true);
      }
    }
  };

  const handlePopupChange = () => {
    setShowPopup(false);
  };

  const iconDisplay = (cName, value) => {
    setDate(new Date(todayDate.getTime() - (30 * 24 * 60 * 60 * 1000)));
    setSecondDate(new Date(todayDate.getTime() - (15 * 24 * 60 * 60 * 1000)));
    if (value === '' || value === undefined) {
      return (<div style={{ marginLeft: 40 }}>NA</div>);
    }
    switch (cName) {
      case 'description':
        return (
          <div>
            <AccountCircleIcon />
            {' '}
            {value}
          </div>
        );
      case 'processedBy':
        return (
          <div>
            <AccountCircleIcon />
            {' '}
            {value}
          </div>
        );
      case 'date':
        return (
          <div>
            <AccessTimeFilledIcon />
            {' '}
            {value.substring(0, 10)}
          </div>
        );

      case 'category':
        if (value === 'bug') {
          return (
            <div style={{ color: 'red' }}>
              <BugReportIcon />
              {' '}
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </div>
          );
        }
        if (value === 'idea') {
          return (
            <div style={{ color: 'green' }}>
              <LightbulbIcon />
              {' '}
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </div>
          );
        }
        if (value === 'other') {
          return (
            <div style={{ color: 'gray' }}>
              <MoreVertIcon />
              {' '}
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </div>
          );
        }
        break;
      case 'sentiments':
        if (value === 'negative') {
          return (
            <div style={{ color: 'red' }}>
              <SentimentVeryDissatisfiedIcon />
              {' '}
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </div>
          );
        }
        if (value === 'positive') {
          return (
            <div style={{ color: 'green' }}>
              <SentimentSatisfiedAltIcon />
              {' '}
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </div>
          );
        }
        if (value === 'neutral') {
          return (
            <div>
              <SentimentNeutralIcon />
              {' '}
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </div>
          );
        }
        break;
      default:
        return '';
    }
    return '';
  };
  const apiCall = () => {
    const apiUrl = `/api/feedback/GetFeedback/${tab}`;
    dispatch(getFeedbacks(apiUrl));
  };
  useEffect(() => {
    apiCall();
  }, [dispatch]);

  useEffect(() => {
    apiCall();
  }, [tab]);

  useEffect(() => {
    if (reloadOpenTab) {
      apiCall();
      setFeedbackContextState({
        ...feedbackContextState,
        reloadOpenTab: false,
      });
    }
  }, [reloadOpenTab]);
  useEffect(() => {
    const s = feedbacks.map((x) => (
      {
        Id: x.id,
        Feedback: x.description,
        Sender: iconDisplay('description', x.userName),
        Date: iconDisplay('date', x.createdOn),
        Category: iconDisplay('category', x.category),
        Sentiments: iconDisplay('sentiments', x.sentiments),
        ProcessedBy: iconDisplay('processedBy', x.processedBy),
      }
    ));
    setTableData(s.map(Object.values));
  }, [feedbacks]);

  function getColumn() {
    const columns = [
      {
        name: 'Id',
        options: {
          display: false,
          download: true,
          filter: false,
          print: false,
          viewColumns: false,
        },
      },
    ];
    columns.push({
      name: 'Feedback',
      options: {
        download: true,
        filter: true,
        viewColumns: false,
        sort: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
      },
    });
    columns.push({
      name: 'Sender',
      options: {
        viewColumns: true,
        sort: true,
        icon: AccountCircleIcon,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            left: 0,
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
      },
    });
    columns.push({
      name: 'Date_time',
      options: {
        download: true,
        filter: true,
        viewColumns: false,
        sort: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            left: 0,
            background: '#f3f6f9',
            zIndex: 8,
          },
          setCellProps: () => ({
            className: 'xsBgColorCell',
            style: {
              textAlign: 'center',
            },
          }),
        }),
      },
    });
    columns.push({
      name: 'Category',
      options: {
        download: true,
        filter: true,
        viewColumns: false,
        sort: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            left: 0,
            background: '#f3f6f9',
            zIndex: 8,
          },

        }),
        setCellProps: () => ({
          className: 'xsBgColorCell',
          style: {
            marginLeft: 70,
          },
        }),
      },
    });
    columns.push({
      name: 'Sentiments',
      options: {
        download: true,
        filter: true,
        viewColumns: false,
        sort: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            left: 0,
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
        setCellProps: () => ({
          className: 'xsBgColorCell',
          style: {
            marginLeft: 70,
          },
        }),
      },
    });
    columns.push({
      name: 'ProcessedBy',
      options: {
        download: true,
        filter: true,
        viewColumns: false,
        sort: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            left: 0,
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
        setCellProps: () => ({
          className: 'xsBgColorCell',
          style: {
            marginLeft: 70,
          },
        }),
      },
    });
    columns.push({
      name: tab === 'inprogress' ? 'Action' : 'view',
      options: {
        download: false,
        filter: false,
        viewColumns: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            left: 0,
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
        customBodyRender: (dataIndex, rowIndex) => (
          <button type="button" className="btn btn-sm" onClick={() => { openPopup(rowIndex.rowData[0]); }}>
            <VisibilityIcon />
          </button>
        ),
      },
    });
    return [...columns];
  }

  const options = {
    filter: true,
    fixedHeader: true,
    fixedSelectColumn: true,
    responsive: 'scroll',
    rowsPerPage: 5,
    expandableRows: false,
    expandableRowsHeader: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    print: false,
    filterType: 'dropdown',
    setRowProps: (rowIndex) => {
      for (let i = 0; i < feedbacks.length; i++) {
        if (feedbacks[i].id === rowIndex[0] && date > new Date(feedbacks[i].createdOn)) {
          return {
            style: { background: '#fadbd888' },
          };
        }
      }
      for (let i = 0; i < feedbacks.length; i++) {
        if (feedbacks[i].id === rowIndex[0] && secondDate > new Date(feedbacks[i].createdOn)) {
          return {
            style: { background: ' #fdebd088' },
          };
        }
      }
      return null;
    },
  };

  return (
    <>
      <div
        className="tab-pane fade show active"
        id="pills-new"
        role="tabpanel"
        aria-labelledby="pills-new-tab"
      >
        <div className="table-responsive">
          <MUIDataTable
            data={tableData}
            columns={getColumn()}
            options={options}
          />
        </div>
      </div>
      {showPopup && <div className="modal-backdrop fade show" />}
      <div>
        <Popup
          isOpen={showPopup}
          feedback={selectedFeedback}
          onChange={handlePopupChange}
          hasAccess={hasAccess}
          tab={tab}
        />
      </div>

    </>
  );
};
export default FeedbackList;
